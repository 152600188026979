import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export const DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
};

@Component({
	selector: 'kt-r-date',
	templateUrl: './r-date.component.html',
	styleUrls: ['./r-date.component.scss'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: 'es-BO'},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
	],
})

export class RDateComponent implements OnInit {
	field: FieldConfig;
	fields: FieldConfig[];
	review: boolean;
	viewS: string;
	viewE: string;
	end: FieldConfig;
	group: FormGroup;
	minDate;
	maxDate;
	minS;
	maxE;
	inTable: boolean = false;
	constructor() {}
	/**
	 * inicializa la pagina
	 */
	ngOnInit() {
		this.end = this.fields.filter(e =>{ return e.fieldname === this.field.rangedate.target;})[0];
		if(!this.review){
			if(this.field.defaultvalue && this.field.defaultvalue!=''){
				let val = this.field.defaultvalue;
				let date = new FormControl(new Date(val.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
				this.group.get(this.field.fieldname).setValue(moment(date.value));
				let dt = new Date(date.value);
				dt.setDate(dt.getDate());
				this.minDate = dt;
			}else{
				if(this.end.enddate){
					this.setMinMax(this.end.enddate);
				}
			}
			if(this.end.defaultvalue && this.end.defaultvalue!=''){
				let val = this.end.defaultvalue;
				let date = new FormControl(new Date(val.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
				this.group.get(this.end.fieldname).setValue(moment(date.value));
				let dt = new Date(date.value);
				dt.setDate(dt.getDate());
				this.maxDate  = dt;
			}else{
				if(this.field.startdate){
					this.setMaxMin(this.field.startdate);
				}
			}
			this.initView(this.field.preview, this.end.preview);
		}
	}
	/**
	 * maneja los eventos 
	 * al seleccionar una fecha
	 *
	 * @param      {string}                       type    tipo [inicio, fin]
	 * @param      {MatDatepickerInputEventDate}  event   valor seleccionado
	 */
	addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
		let val = new Date(event.value);
	    if(type == 'min'){
	    	val.setDate(val.getDate());
	    	this.minDate = val;
	    }
	    else{
	    	val.setDate(val.getDate());	    	
	    	this.maxDate = val;
	    }
	}
	/**
	 * inicializar vista del componente
	 *
	 * @param      {string}  type1   tipo de vista fecha inicio
	 * @param      {string}  type2   tipo de vista fecha fin
	 */
	initView(type1: string, type2: string){
		if(this.field.startdate){
			this.setMin(this.field.startdate);
		}
		if(this.end.enddate){
			this.setMax(this.end.enddate);
		}
		switch (type1) {
			case "day":
				this.viewS = 'month';
				break;
			case "month":			
				this.viewS = 'year';
				break;
			case "year":
				this.viewS = 'multi-year';
				break;			
			default:
				this.viewS = 'month';
				break;
		}
		switch (type2) {
			case "day":
				this.viewE = 'month';
				break;
			case "month":			
				this.viewE = 'year';
				break;
			case "year":
				this.viewE = 'multi-year';
				break;			
			default:
				this.viewE = 'month';
				break;
		}
	}
	/**
	 * Sets the minimum.
	 *
	 * @param      {string}  min     fecha minima a seleccionar
	 */
	setMin(min: string){
		let date;
		switch (min) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(min.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.minS = date;
	}
	/**
	 * Sets the maximum.
	 *
	 * @param      {string}  max     fecha maxima a seleccionar
	 */
	setMax(max:string){
		let date;
		switch (max) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(max.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.maxE = date;
	}
	/**
	 * Sets the minimum maximum.
	 *
	 * @param      {string}  max     agraga fecha minima por defecto
	 */
	setMinMax(max: string){
		let date;
		switch (max) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(max.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}
		this.maxDate = date;
	}
	/**
	 * agrega fecha maxima por defecto
	 *
	 * @param      {string}  min     The new value
	 */
	setMaxMin(min: string){
		let date;
		switch (min) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(min.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.minDate = date;
	}
}