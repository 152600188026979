import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'kt-preview',
	templateUrl: './preview.component.html',
	styles: ['']
})
export class PreviewComponent implements OnInit {
	id: string;
	constructor(private active: ActivatedRoute) { }
	ngOnInit() {
		/**
		 * { Inicializador }
		 *
		 * @param      {<ActivatedRoute>}  active obtiene el id de la Postulacion proveniente de la URL
		 */
		this.active.paramMap.subscribe(params => {
			this.id = params.get("id");
		});
	}

}
