import { ComponentFactoryResolver, ComponentRef, Input, Output, EventEmitter, OnInit, ViewContainerRef, Directive } from '@angular/core';
import { FormGroup } from "@angular/forms";

import { FieldConfig } from "../../field.interface";
import { HtmlComponent } from "../html/html.component";
import { TextboxComponent } from "../textbox/textbox.component";
import { TextareaComponent } from "../textarea/textarea.component";
import { SDateComponent } from "../s-date/s-date.component";
import { RDateComponent } from "../r-date/r-date.component";
import { SelectComponent } from "../select/select.component";
import { CheckComponent } from "../check/check.component";
import { FileComponent } from "../file/file.component";
import { FileViewComponent } from "../file-view/file-view.component";
import { TableComponent } from "../table/table.component";
import { BudgetComponent } from "../budget/budget.component";
import { ScheduleComponent } from "../schedule/schedule.component";
import { ButtonComponent } from "../button/button.component";

const componentMapper = {
	html: HtmlComponent,
	textbox: TextboxComponent,
	textarea: TextareaComponent,
	datepicker: SDateComponent,
	rdate: RDateComponent,
	select: SelectComponent,
	checkbox: CheckComponent,
	fileupload: FileComponent,
	fileview: FileViewComponent,
	table: TableComponent,
	budget: BudgetComponent,
	schedule: ScheduleComponent,
	button: ButtonComponent,
};
@Directive({
	selector: '[dynamicField]'
})

export class DynamicFieldDirective {
	@Input() field: FieldConfig;
	@Input() fields: FieldConfig[];
	@Input() group: FormGroup;
	@Input() hash: string;
	@Input() table: boolean = false;
	@Input() review: boolean = false;
	@Input() preview: boolean = false;
	@Output() ready: EventEmitter<any> = new EventEmitter<any>();
	componentRef: any;

	constructor(
		private resolver: ComponentFactoryResolver, 
		private container: ViewContainerRef
	) { }
	/**
	 * inicializa el valor de cada elemento del formulario dinamico
	 * determina cualidades para cada elemento
	 * columnas, posicion
	 * manejo de casos especiales
	 * archivos, rangos de fecha
	 * escucha cuando los elementos indican que estan listo
	 * y retornca cada evento al formulario dinamico principal
	 */
	ngOnInit() {
		let type = this.field.type;
		if(this.field.subtype =='rangedate'){
			if(this.field.rangedate.type=='start'){
				type = 'rdate';
			}
			if(this.field.rangedate.type=='end'){
				return;
			}
		}
		if(this.field.type == 'hidden'){
			return;
		}
		if(this.field.subtype == 'tablebudget'){
			type = 'budget';
		}
		if(this.field.subtype == 'tableschedule'){
			type = 'schedule';
		}
		if(this.field.subtype == 'fileupload' && this.review){
			type = 'fileview';
		}
		const factory = this.resolver.resolveComponentFactory(
			componentMapper[type]
		);
		this.componentRef = this.container.createComponent(factory);
		this.componentRef.instance.field = this.field;
		if((this.field.subtype=='select' && !this.field.search) || (this.field.type=='select' && this.field.optionstype!='custom') || this.field.type=='checkbox'){
			this.componentRef.instance.ready.subscribe($event => {
			    this.isReady($event);
			});
		}
		if(this.review){
			this.componentRef.instance.review = this.review;
		}else{
			this.componentRef.instance.group = this.group;	
		}
		if(this.field.type == 'fileupload' || this.field.type == 'table'){
			this.componentRef.instance.hash = this.hash;
			if(this.preview){
				this.componentRef.instance.preview = this.preview;
			}
		}
		if(this.field.subtype == 'rangedate'){
			this.componentRef.instance.fields = this.fields;
		}
		if(this.field.gridcolumn && this.field.gridcolumn !=''){
			this.componentRef._view.nodes[0].renderElement.className = this.field.gridcolumn;
		}
		if(this.field.subtype =='rangedate'){
			this.componentRef._view.nodes[0].renderElement.className = 'col-12';	
		}
		if(this.field.subtype == 'divider'){
			this.componentRef._view.nodes[0].renderElement.className = 'col-12';
		}
		if(this.table && (this.field.type == 'select' || this.field.type == 'fileupload' || this.field.type == 'checkbox' || this.field.subtype == 'rangedate')){
			this.componentRef.instance.inTable = this.table;
		}
	}
	/**
	 * emite eventos de estado para cada elemento
	 * @param {elemento} event elemento que emite el evento
	 */
	isReady(event){
		this.ready.emit(event);
	}
}
