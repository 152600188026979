import { Component, Input, OnInit, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
/*---NGRX---*/
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { currentUserId } from '../../../../../core/auth';

@Component({
  selector: 'kt-table-winners',
  templateUrl: './table-winners.component.html',
  styleUrls: ['./table-winners.component.scss']
})
export class TableWinnersComponent implements OnInit {
	@Input() type: string;
	@Input() files: any;
	@Output() isReady: EventEmitter<any> = new EventEmitter();
	id: string;
	postulations: Postulation[] = [];
	displayedColumns;
	_dataSource: MatTableDataSource<Postulation> = new MatTableDataSource(this.postulations);
	dutchRangeLabel = (page: number, pageSize: number, length: number) => {
	  	if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
	  	length = Math.max(length, 0);
	  	const startIndex = page * pageSize;
	  	const endIndex = startIndex < length?Math.min(startIndex + pageSize, length):startIndex + pageSize;
	  	return `${startIndex + 1} - ${endIndex} de ${length}`;
	}
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	constructor(
		private store: Store<AppState>,
		private http: HttpClient,
		private router: Router,
		private toastr: ToastrService, 
	) { }
	/**
	 * obtiene el id de usuario desde el stor
	 */
	ngOnInit() {
		let t: Observable<any> = this.store.pipe(select(currentUserId));
		t.subscribe(
			res=>{
				if(res!=null && res!=this.id){
					this.id = res;
					this.getPostulations(this.id);
				}
			}
		);
	}
	/**
	 * ordena los elementos de la tabla 
	 * de acuerdo a la columna activa
	 */
	ngAfterViewInit(): void {
	    this._dataSource.sort = this.sort;
	}
	/**
	 * obtinen listado de postulaciones
	 *
	 * @param      {string}  id      id de usuario
	 */
	getPostulations(id: string){
		let type = (this.type=='borradores')?'draft':'submited';
		if(this.type=='borradores'){
			this.displayedColumns = ['hash', 'area_intervention', 'convocatorie', 'perfil', 'limit', 'update', 'status', 'options'];
		}else{
			this.displayedColumns = ['hash', 'area_intervention', 'convocatorie', 'perfil', 'send_postulation_date', 'status', 'discharge', 'options'];
		}
		let apiUrl = `${environment.apiUrl}/a/discharges/postulations/list?include=discharge,convocatory&limit=100&orderBy=postulations.updated_at&sortedBy=desc`;
		let resp:Observable<any> = this.http.get(apiUrl);
		resp.subscribe(
			data=>{
				for(let d of  data.data){
					let c ={
						id: d.id,
						hash: d.hash,
						code: d.convocatory.data.code,
						type: d.convocatory.data.type,
						name: d.convocatory.data.name,
						version: d.convocatory.data.version,
						perfil: (d.person.data.type=='PN')?'Persona Natural':(d.person.data.type=='PJ')?'Persona Juridica':'Colectivo / Org. Civil',
						status: d.status,
						area_intervention: d.area_intervention.data.name,
						finish_postulation_date: d.convocatory.data.finish_postulation_date,
						modify_postulation_date: new Date(d.updated_at.date),
						send_postulation_date: (d.postulation_date)?d.postulation_date.date:null,
						discharge: (d.discharge)?d.discharge.data:null,
						convocatorie: `${d.convocatory.data.type} ${d.convocatory.data.name} ${(d.convocatory.data.version)?d.convocatory.data.version:''} ${d.convocatory.data.code}`
					};
					this.postulations.push(<Postulation>c);			
				}
				this._dataSource = new MatTableDataSource(this.postulations);
				this.paginator._intl.itemsPerPageLabel = 'Postulaciones por Página';
				this.paginator._intl.firstPageLabel = 'Primer Página';
				this.paginator._intl.lastPageLabel = 'Ultima Página';
				this.paginator._intl.nextPageLabel = 'Siguiente';
				this.paginator._intl.previousPageLabel = 'Anterior';
				this.paginator._intl.getRangeLabel = this.dutchRangeLabel;
				this._dataSource.paginator = this.paginator;
				this._dataSource.sort = this.sort;
				this.isReady.emit(type);
			},
      		error=>{
      			this.catchError(error);
      			this._dataSource = new MatTableDataSource(this.postulations);
				this._dataSource.paginator = this.paginator;
				
      		},
		);
	}
	/**
	 * ir a editar un descargo
	 *
	 * @param      {string}  code    codigo de convocatoria
	 * @param      {string}  hash    hash de postulacion
	 */
	goPostulation(code: string, hash:string){
		let cleanName = this.cleanName(name);
		this.router.navigateByUrl(`/descargos/convocatoria/${code}/editar/${hash}`);
	}
	/**
	 * ir al detalle de un descargo
	 *
	 * @param      {string}  code    codigo convocatoria
	 * @param      {string}  hash    hash de postulacion
	 */
	toDetail(code: string, hash: string){
		this.router.navigateByUrl(`/postulaciones/detalle/${code}/${hash}`);
	}
	/**
	 * busqueda en la tabla
	 *
	 * @param      {string}  filterValue  valor buscado
	 */
	_applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this._dataSource.filter = filterValue;
	}
	/**
	 * limpia caracteres especiales de una cadena
	 *
	 * @param      {string}  name    nombre de convocatoria
	 * @return     {string} { cadena limpia de caracteres especiales }
	 */
	cleanName(name: string):string{
		let r = name.toLowerCase();
		r = r.replace(new RegExp(/\s/g),"-");
	    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
	    r = r.replace(new RegExp(/æ/g),"ae");
	    r = r.replace(new RegExp(/ç/g),"c");
	    r = r.replace(new RegExp(/[èéêë]/g),"e");
	    r = r.replace(new RegExp(/[ìíîï]/g),"i");
	    r = r.replace(new RegExp(/ñ/g),"n");                
	    r = r.replace(new RegExp(/[òóôõö]/g),"o");
	    r = r.replace(new RegExp(/œ/g),"oe");
	    r = r.replace(new RegExp(/[ùúûü]/g),"u");
	    r = r.replace(new RegExp(/[ýÿ]/g),"y");
	    r = r.replace(new RegExp(/\W/g),"-");
		return r;
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<error>}  e    error producido por una peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * obtener el reporte PDf de un descargo enviado
	 *
	 * @param      {string}  hash    hash de postulacion
	 */
	getPDF(hash: string){
		let apiUrl = `${environment.apiUrl}/a/discharges/postulation/${hash}/report`;
		let resp:Observable<any> = this.http.get(apiUrl);
		resp.subscribe(
			data=>{
				this.toastr.success('Reporte Generado', 'Exito!')
				setTimeout(()=> window.open(data.url, '_blank'), 1000);
			},
      		error=>{
      			this.catchError(error);
      		},
		);
	}
}
/**
 * interface de descargo
 * modelo de datos que maneja el descargo de una postulacion
 */
export interface Postulation{
	id: string;
	hash: string;
	code: string;
	type: string;
	name: string;
	perfil: string;
	status: string;
	area_intervention: string;
	finish_postulation_date: any;
	modify_postulation_date: any;
	discharge?: any;
	send_postulation_date?: any;
	version?: any;
	convocatorie?: string;
}