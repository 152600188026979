import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Auth
import { AuthEffects, AuthGuard, ConfirmedGuard, CompleteGuard, authReducer, AuthService } from '../../../../core/auth';
import { 
	MatTooltipModule, 
	MatFormFieldModule, 
	MatIconModule, 
	MatPaginatorModule, 
	MatProgressSpinnerModule, 
	MatSortModule, 
	MatTableModule, 
	MAT_DATE_LOCALE, 
	MAT_DATE_FORMATS, 
	MatTabsModule, 
	MatListModule} from '@angular/material';
import { CoreModule } from '../../../../core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
//forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
	MatDatepickerModule, 
	MatDatepickerIntl, 
	MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
//dropzone
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
//multiselect
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//toastr
import { ToastrModule } from 'ngx-toastr';
//dinamic forms
import { HtmlComponent } from './elements/html/html.component';
import { TextboxComponent } from './elements/textbox/textbox.component';
import { DynamicFieldDirective } from './elements/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './elements/dynamic-form/dynamic-form.component';
import { ReviewFormComponent } from './elements/review-form/review-form.component';
import { ButtonComponent } from './elements/button/button.component';
import { TextareaComponent } from './elements/textarea/textarea.component';
import { SDateComponent } from './elements/s-date/s-date.component';
import { RDateComponent } from './elements/r-date/r-date.component';
import { SelectComponent } from './elements/select/select.component';
import { CheckComponent } from './elements/check/check.component';
import { FileComponent } from './elements/file/file.component';
import { FileViewComponent } from './elements/file-view/file-view.component';
import { TableComponent } from './elements/table/table.component';
import { BudgetComponent } from './elements/budget/budget.component';
import { ScheduleComponent } from './elements/schedule/schedule.component';
import { FormPreviewComponent } from './elements/form-preview/form-preview.component';
import { TableConvocatoriesComponent } from './table-convocatories/table-convocatories.component';
import { TablePostulationsComponent } from './table-postulations/table-postulations.component';
import { FormPerfilComponent } from './form-perfil/form-perfil.component';
import { FormUserComponent } from './form-user/form-user.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafePipe } from './safe.pipe';
import { LoadingComponent } from './loading/loading.component';
import { TableBudgetComponent } from './discharges/table-budget/table-budget.component';
import { TableScopeComponent } from './discharges/table-scope/table-scope.component';
import { TableCommunicationComponent } from './discharges/table-communication/table-communication.component';
import { TableJobComponent } from './discharges/table-job/table-job.component';
import { DischargeFormComponent } from './discharges/discharge-form/discharge-form.component';
import { TableWinnersComponent } from './table-winners/table-winners.component';
import { NumberPipe } from './number.pipe';
import { environment } from '../../../../../environments/environment';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
	url: environment.apiUrl+'/files',
	maxFilesize: 1,
	maxFiles: 1,
	paramName:'file',
	acceptedFiles: 'application/pdf',
	addRemoveLinks: true,
	dictDefaultMessage:'Arrastra los archivos aquí para subirlos',
	dictFallbackMessage:'Su navegador no admite la carga de archivos con arrastrar y soltar.',
	dictFallbackText:'Por favor, use el formulario de reserva a continuación para cargar sus archivos como en los días anteriores.',
	dictFileTooBig:'El archivo es muy grande ({{filesize}}MB) el limite es de {{maxFilesize}}MB',
	dictInvalidFileType:'No puedes subir archivos de este tipo.',
	dictResponseError:'El servidor respondió con el código {{statusCode}}.',
	dictCancelUpload:'Cancelar la Carga del Archivo',
	dictUploadCanceled:'Carga de Archivo cancelada.',
	dictCancelUploadConfirmation:'¿Estás seguro de que quieres cancelar la carga del archivo?',
	dictRemoveFile:'Eliminar archivo',
	dictMaxFilesExceeded:'No puedes subir más archivos.',
};

@NgModule({
	declarations: [
		TableConvocatoriesComponent,
		FormPerfilComponent,
		HtmlComponent,
		TextboxComponent,
		DynamicFieldDirective,
		DynamicFormComponent,
		ReviewFormComponent,
		ButtonComponent,
		TextareaComponent,
		SDateComponent,
		RDateComponent,
		SelectComponent,
		CheckComponent,
		FileComponent,
		FileViewComponent,
		TableComponent,
		BudgetComponent,
		ScheduleComponent,
		FormUserComponent,
		SafeHtmlPipe,
		SafePipe,
		TablePostulationsComponent,
		FormPreviewComponent,
		LoadingComponent,
		TableBudgetComponent,
		TableScopeComponent,
		TableCommunicationComponent,
		TableJobComponent,
		DischargeFormComponent,
		TableWinnersComponent,
		NumberPipe,
	],
	exports: [
		TableConvocatoriesComponent,
		TablePostulationsComponent,
		FormPerfilComponent,
		FormUserComponent,
		DynamicFieldDirective,
		DynamicFormComponent,
		ReviewFormComponent,
		FormPreviewComponent,
		LoadingComponent,
		DischargeFormComponent,
		TableWinnersComponent,
		MatSortModule,
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'es-BO'},
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{ provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG }
	],
	imports: [
		NgbModule ,
		CommonModule,
    	ToastrModule.forRoot(),
    	NgMultiSelectDropDownModule.forRoot(),
		CdkTableModule,
		PerfectScrollbarModule,
		MatTableModule,
		MatDatepickerModule,
		CoreModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatTabsModule,
		MatTooltipModule,
		MatListModule,
		DropzoneModule,
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
	],
	entryComponents: [
		HtmlComponent,
		TextboxComponent,
		TextareaComponent,
		SDateComponent,
		RDateComponent,
		SelectComponent,
		CheckComponent,
		FileComponent,
		FileViewComponent,
		TableComponent,
		BudgetComponent,
		ScheduleComponent,
		ButtonComponent
	]
})
export class WidgetModule {
}
