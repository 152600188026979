// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    refreshToken?: string;
    expire?: number;
    confirmed?:boolean;
    user: User;
    isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    refreshToken: undefined,
    expire: undefined,
    confirmed: false,
    user: undefined,
    isUserLoaded: false
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.Login: {
            const _token: string = action.payload.authToken;
            const _refToken: string = action.payload.refreshToken;
            const _expireToken: number = action.payload.expire;
            const _confirmed: boolean = action.payload.confirmed;
            return {
                loggedIn: true,
                authToken: _token,
                refreshToken: _refToken,
                expire: _expireToken,
                confirmed: _confirmed,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Register: {
            const _token: string = action.payload.authToken;
            return {
                loggedIn: true,
                authToken: _token,
                user: undefined,
                isUserLoaded: false
            };
        }

        case AuthActionTypes.Logout:
            return initialAuthState;

        case AuthActionTypes.UserLoaded: {
            const _user: User = action.payload.user;
            if(typeof _user == 'object'){
                return {
                    ...state,
                    user: _user,
                    isUserLoaded: true
                };
            }else{
                return {...state};
            }
        }

        case AuthActionTypes.UserConfirmed: {
            const _confirmed: boolean = action.payload.confirmed;
            return {
                ...state,
                confirmed: _confirmed
            };
        }

        case AuthActionTypes.RefreshToken: {
            const _token: string = action.payload.authToken;
            const _refToken: string = action.payload.refreshToken;
            const _expireToken: number = action.payload.expire;
            return {
                ...state,
                authToken: _token,
                refreshToken: _refToken,
                expire: _expireToken
            };
        }

        default:
            return state;
    }
}
