// Angular
import { Component, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, currentAuthToken, currentAuth, currentUserId, Logout, User, AuthService } from '../../../../../core/auth';


@Component({
	selector: 'kt-user-profile3',
	templateUrl: './user-profile3.component.html',
})
export class UserProfile3Component implements OnInit {
	// Public properties
	user$: Observable<User>;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(private store: Store<AppState>, private auth:AuthService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.store.pipe(select(currentUser));
		let t: Observable<any> = this.store.pipe(select(currentUserId));
		t.subscribe(
			res=>{
				//console.log(res);
			}
		);

	}

	/**
	 * Log out
	 */
	logout() {
		let r: Observable<any> = this.auth.logout();
		r.subscribe(response=>{
			this.store.dispatch(new Logout());
		},
		error=>{
			this.store.dispatch(new Logout());
		});
		
	}
}
