import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";

@Component({
	selector: 'kt-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss']
})
/**
 * 
 * elemento textarea en formulario dinamico
 *
 * @class      TextareaComponent (name)
 */
export class TextareaComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	constructor() { }
	ngOnInit() {
	}
}
