import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
	name: 'safe'
})
export class SafePipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer){}
	/**
	 * pipe url para iframes
	 *
	 * @param      {string}  url     url
	 * @return     {<sanitizeHtml>} { url lista para usarse en iframes o [href] }
	 */
	transform(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

}
