export class User{
    id: any;
    username: string;
    email: string;
    confirmed?: boolean;
    accessToken?: string;
    refreshToken?: string;
    expire?:number;
    roles?: any[];

    clear(): void {
        this.id = undefined;
        this.username = '';
        this.email = '';
        this.roles = [];
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
    }
}
