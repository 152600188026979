import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toNumber'
})
export class NumberPipe implements PipeTransform {
	/**
	 * pipe para formato de numeros
	 *
	 * @param      {any}  value   numero
	 * @param      {any}  args    argumentos
	 * @return     {any}  { numero con formato para mostrar en detalles }
	 */
	transform(value: any, args?: any): any {
		let r = Number(value).toLocaleString('en-GB');
		return r;
	}
}
