import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Output, EventEmitter  } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MatListModule } from '@angular/material/list';

@Component({
	selector: 'kt-check',
	templateUrl: './check.component.html',
	styleUrls: ['./check.component.scss'],
})

export class CheckComponent implements OnInit, AfterViewInit {
	field: FieldConfig;
	group: FormGroup;
	options: options[];
	review: boolean;
	inTable: boolean = false;
	@Output() ready: EventEmitter<any> = new EventEmitter<any>();
	enable: boolean = false;

	constructor(private cdRef:ChangeDetectorRef) { }
	/**
	 * 
	 * inicializar componente
	 */
	ngOnInit() {
		if(this.field.readonly){
			this.group.get(this.field.fieldname).disable();
		}
		this.initOptions();
		if(!this.review){
			this.setValue();		
		}
	}
	/**
	 * deteccion de cambios
	 */
	ngAfterViewInit(){
		this.cdRef.detectChanges();
	}
	/**
	 * inicializar opciones del checkbox
	 */
	initOptions(){
		if(this.field.options){
			let op = []
			this.field.options.forEach(v=>{
				let o = {
					text: v.text,
					value: v.value
				}
				op.push(o);
			});
			this.options = op;
		}else{
			this.options = [];
		}
	}
	/**
	 * agregar valores al componente
	 */
	setValue(){
		if(this.field.defaultvalue && this.field.defaultvalue!=''){
			let o = this.options.filter(v=>{
				return this.field.defaultvalue.filter(f=>{ return f.value === v.value }).length>0;
			});
			this.group.get(this.field.fieldname).setValue(o);
			this.group.get(this.field.fieldname).updateValueAndValidity({emitEvent:false});
			this.ready.emit(this.field.fieldname);
		}else{
			this.ready.emit(this.field.fieldname);
		}
		this.enable = true;
	}
	/**
	 * obtener valor seleccionado
	 *
	 * @param      {<any>}  value   valor a obtener
	 */
	getValue(value){
		let v = '';
		if(value && value!=''){
			v = value;
		}
		return v;
	}
}
/**
 * interface de opciones
 */
export interface options{
	text?: string;
	value?: string;
	selected?: boolean;
}