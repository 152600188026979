import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";

@Component({
	selector: 'kt-html',
	templateUrl: './html.component.html',
	styleUrls: ['./html.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HtmlComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	content: string;
	constructor() { }
	/**
	 * genera el contenido del componente
	 */
	ngOnInit() {
		switch (this.field.subtype) {
			case "paragraph":
				this.content = `<p class='${(this.field.classname)?this.field.classname:''}'>${this.field.text}</p>`;
				break;
			case "image":
				this.content = `<img src='${this.field.sourceimage}' class='${(this.field.classname)?this.field.classname:''}' />`;
				break;
			case "divider":
				this.content = `<hr class='${(this.field.classname)?this.field.classname:''}' >`;
				break;
			default:
				// code...
				break;
		}
	}

}
