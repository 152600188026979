// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatIconModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatTooltipModule
} from '@angular/material';
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// Core module
import { CoreModule } from '../../core/core.module';
// CRUD Partials
import { ActionNotificationComponent, AlertComponent } from './content/crud';
// Layout partials
import {
	ContextMenu2Component,
	ContextMenuComponent,
	NotificationComponent,
	QuickPanelComponent,
	ScrollTopComponent,
	SplashScreenComponent,
	StickyToolbarComponent,
	Subheader4Component,
	UserProfile3Component
} from './layout';
// General
//import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';
// Extra module
import { WidgetModule } from './content/widgets/widget.module';

@NgModule({
	declarations: [
		ScrollTopComponent,
		
		ActionNotificationComponent,
		AlertComponent,
		
		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader4Component,
		NotificationComponent,
		UserProfile3Component,
		ErrorComponent,
	],
	exports: [
		WidgetModule,
		PortletModule,
		ScrollTopComponent,
		
		ActionNotificationComponent,
		AlertComponent,
		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader4Component,
		NotificationComponent,
		UserProfile3Component,

		ErrorComponent,
	],
	imports: [
		CommonModule,
		PortletModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		PerfectScrollbarModule,
		CoreModule,
		WidgetModule,
		MatIconModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
	]
})
export class PartialsModule {
}
