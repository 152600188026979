// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, Login } from '../../../../core/auth/';
import { Subject, throwError } from 'rxjs';
import { ConfirmPasswordValidator } from '../register/confirm-password.validator';
import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'kt-reset-password',
  templateUrl: './reset-password.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	private verifyUrl: any;
	email:string;
	token:string;
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		
		this.route.queryParamMap.subscribe((res: any) => {
			if(res.keys.length>0){
				this.email = res.params.email;
				this.token = res.params.token;
				this.initRestForm(this.email, this.token);
			}
			else{
				this.router.navigateByUrl('/auth/login');
			}
		});
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRestForm(email:string, token:string) {
		this.registerForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
			]),
			],
			token: [token, Validators.compose([
				Validators.required,
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
			])
			],
		}, {
			validator: [ConfirmPasswordValidator.MatchPassword]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const _user ={
			email: controls['email'].value,
			token: controls['token'].value,
			password: controls['password'].value,
		};
		this.auth.resetPassword(_user).pipe(
			tap(user => {
				if (user) {
					this.authNoticeService.setNotice('Cambio de Contraseña Exitoso', 'success');
					this.router.navigateByUrl('/auth/login'); // Main page
				} else {
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				}
			}),
			catchError((err) => {
		        this.catchError(err);
		        return throwError(err);
		    }),			
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	catchError(e){
		if(typeof e == 'object'){
			this.authNoticeService.setNotice(e.error.message, 'danger');
		}
		else{			
			this.authNoticeService.setNotice(e.toString(), 'danger');
		}
	}
}
