import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export const DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
};

@Component({
	selector: 'kt-s-date',
	templateUrl: './s-date.component.html',
	styleUrls: ['./s-date.component.scss'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: 'es-BO'},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
	],
})

export class SDateComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	view: string;
	minDate;
	maxDate;
	constructor() { }
	/**
	 * inicializa la pagina
	 */
	ngOnInit() {
		if(!this.review){
			if(typeof this.field.defaultvalue != 'object' && this.field.defaultvalue && this.field.defaultvalue!=''){
				let val = this.field.defaultvalue;
				let date = new FormControl(new Date(val.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
				this.group.get(this.field.fieldname).setValue(moment(date.value));
			}
			this.initView(this.field.preview);
		}
	}
	/**
	 * inicializa el estado del datepicker
	 *
	 * @param      {string}  type    tipo de vista a desplegar
	 */
	initView(type: string){
		if(this.field.startdate){
			this.setMin(this.field.startdate);
		}
		if(this.field.enddate){
			this.setMax(this.field.enddate);
		}
		switch (type) {
			case "day":
				this.view = 'month';
				break;
			case "month":			
				this.view = 'year';
				break;
			case "year":
				this.view = 'multi-year';
				break;			
			default:
				this.view = 'month';
				break;
		}
	}
	/**
	 * Sets the minimum.
	 *
	 * @param      {string}  min     fecha minima a seleccionar
	 */
	setMin(min: string){
		let date;
		switch (min) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(min.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.minDate = date;
	}
	/**
	 * Sets the maximum.
	 *
	 * @param      {string}  max     fecha maxima a seleccionar
	 */
	setMax(max:string){
		let date;
		switch (max) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(max.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.maxDate = date;
	}
}
