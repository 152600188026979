// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isUserConfirmed } from '../_selectors/auth.selectors';

@Injectable()
export class CompleteGuard implements CanActivate {
    constructor(private store: Store<AppState>, private router: Router) { }
    /**
     * verifica si un usuario confirmo su email
     * @param  {ActivatedRouteSnapshot} route navegacion activa
     * @param  {RouterStateSnapshot}    state ngrx estado
     * @return {Observable<boolean>}          valor de verdad
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        return this.store
            .pipe(
                select(isUserConfirmed),
                map(Confirmed => {
                    if (Confirmed) {
                        this.router.navigateByUrl('/');
                        return false;
                    }
                    return true;
                })
            );
    }
}
