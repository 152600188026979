import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'kt-discharge-form',
	templateUrl: './discharge-form.component.html',
	styleUrls: ['./discharge-form.component.scss']
})

export class DischargeFormComponent implements OnInit {
	field: any;
	sending: boolean = false;
	dischargeForm: FormGroup;
	neddOther:boolean = false;
	@Input() data: any = {};
	@Input() type: string = '';
	@Input() proccess: boolean = false;
	@Input() status: string;
	@Output() isReady: EventEmitter<any> = new EventEmitter();
	@Output() save: EventEmitter<any> = new EventEmitter();
	@Output() submit: EventEmitter<any> = new EventEmitter();
	@Output() back: EventEmitter<any> = new EventEmitter();

	options: any = {
		op1: '0',
		op2: '0',
		op3: '0',
		op4: '0',
		op5: '0',		
	};

	constructor(
		private http: HttpClient,
		private fb: FormBuilder,
		private toastr: ToastrService,
	) { }
	/**
	 * inicializar pagina
	 */
	ngOnInit() {	
		this.setOptions();
		this.initForm();
	}
	/**
	 * inicializa formulario
	 */
	initForm(){
		let valFOR = [Validators.pattern(/^\d+$/), Validators.max(9999999)];
		if(this.type == '01'){
			valFOR = [Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)];
		}
		let ValEMP = [Validators.required, Validators.maxLength(10)];
		if(this.type == '02'){
			ValEMP = [Validators.maxLength(10)];
		}
		this.dischargeForm = this.fb.group({
			amount_piu: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)]],
			amount_others: ['', [Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)]],
			price_ticket: ['', valFOR],
			expenses_by_financing: ['', [Validators.required, Validators.maxLength(100)]],
			scope_project: ['', [Validators.required, Validators.maxLength(20)]],
			comunication_scope: ['', [Validators.required, Validators.maxLength(20)]],
			employment_generated: ['', ValEMP],
			reinvest_project: ['', [Validators.required]],
			destination_income: ['', [Validators.maxLength(2)]],
			other_destination_income: ['', [Validators.maxLength(100)]],
			percentage_support: ['', [Validators.pattern(/^\d+$/), Validators.max(100)]],
			comment_importance: ['', [Validators.required, Validators.minLength(500), Validators.maxLength(3000)]],
			comment_suggestion: ['', [Validators.required, Validators.minLength(500), Validators.maxLength(3000)]],
			update: ['', [Validators.required]]
		});
		if(this.data){
			this.dischargeForm.patchValue(this.data);
			this.dischargeForm.updateValueAndValidity({emitEvent:false});
			this.requireOther();
		}
		this.isReady.emit(true);
	}
	/**
	 * agrega validacion 
	 * si es requerida
	 */
	requireOther(){
		let val: string[] = []
		if(this.dischargeForm.get('destination_income').value){
			val = this.dischargeForm.get('destination_income').value;
		}		 
		let isIn: boolean = false;
		val.forEach(d=>{
			if(d=='Otros'){
				isIn = true;
			}
		});
		if(isIn){
			if(!this.neddOther){
				this.dischargeForm.get('other_destination_income').setValidators([Validators.required, Validators.maxLength(100)]);
				this.dischargeForm.get('other_destination_income').updateValueAndValidity({emitEvent:false});
				this.neddOther = true;
			}
		}else{
			if(this.neddOther){
				this.dischargeForm.get('other_destination_income').setValidators([Validators.maxLength(100)]);
				this.dischargeForm.get('other_destination_income').updateValueAndValidity({emitEvent:false});
				this.neddOther = false;
			}
		}
	}
	/**
	 * pre verificado de datos 
	 * antes de ser guardados / enviados
	 *
	 * @param      {<any>}  event   The event
	 */
	onSave(event){
		this.sending = true;
		this.aOrRequired();
		this.dischargeForm.get('update').setValue('save');
		this.dischargeForm.get('update').updateValueAndValidity({emitEvent:false});
		if(this.dischargeForm.valid){
			this.save.emit(this.dischargeForm.value);
		}else{
			this.toastr.error('Por favor, verifique detalladamente los campos del formulario.', '¡Advertencia!');
			this.validateAllFormFields(this.dischargeForm);
			this.sending = false
		}
	}
	/**
	 * agregar o quitar validacion de campos requeridos
	 *
	 * @param      {string}  type    tipo de cambio
	 */
	aOrRequired(type='noRequired'){
		if(type == 'noRequired'){
			this.dischargeForm.get('amount_piu').setValidators([Validators.pattern(/^\d+$/), Validators.max(9999999)]);
			this.dischargeForm.get('amount_others').setValidators([Validators.pattern(/^\d+$/), Validators.max(9999999)]);
			this.dischargeForm.get('price_ticket').setValidators([Validators.pattern(/^\d+$/), Validators.max(9999999)]);
			this.dischargeForm.get('expenses_by_financing').setValidators([Validators.maxLength(100)]);
			this.dischargeForm.get('scope_project').setValidators([Validators.maxLength(20)]);
			this.dischargeForm.get('comunication_scope').setValidators([Validators.maxLength(20)]);
			this.dischargeForm.get('employment_generated').setValidators([Validators.maxLength(10)]);
			this.dischargeForm.get('reinvest_project').setValidators([]);
			this.dischargeForm.get('destination_income').setValidators([Validators.maxLength(2)]);
			this.dischargeForm.get('other_destination_income').setValidators([Validators.maxLength(100)]);
			this.dischargeForm.get('comment_importance').setValidators([Validators.minLength(500), Validators.maxLength(3000)]);
			this.dischargeForm.get('comment_suggestion').setValidators([Validators.minLength(500), Validators.maxLength(3000)]);
		}else{
			let valFOR = [Validators.pattern(/^\d+$/), Validators.max(9999999)];
			if(this.type == '01'){
				valFOR = [Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)];
			}
			let ValEMP = [Validators.required, Validators.maxLength(10)];
			if(this.type == '02'){
				ValEMP = [Validators.maxLength(10)];
			}
			this.dischargeForm.get('amount_piu').setValidators([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)]);
			this.dischargeForm.get('amount_others').setValidators([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)]);
			this.dischargeForm.get('price_ticket').setValidators(valFOR);
			this.dischargeForm.get('expenses_by_financing').setValidators([Validators.required, Validators.maxLength(100)]);
			this.dischargeForm.get('scope_project').setValidators([Validators.required, Validators.maxLength(20)]);
			this.dischargeForm.get('comunication_scope').setValidators([Validators.required, Validators.maxLength(20)]);
			this.dischargeForm.get('employment_generated').setValidators(ValEMP);
			this.dischargeForm.get('reinvest_project').setValidators([]);
			this.dischargeForm.get('destination_income').setValidators([Validators.required, Validators.maxLength(2)]);
			if(this.neddOther){
				this.dischargeForm.get('other_destination_income').setValidators([Validators.required, Validators.maxLength(100)]);
			}else{
				this.dischargeForm.get('other_destination_income').setValidators([Validators.maxLength(100)]);
			}
			this.dischargeForm.get('comment_importance').setValidators([Validators.required, Validators.minLength(500), Validators.maxLength(3000)]);
			this.dischargeForm.get('comment_suggestion').setValidators([Validators.required, Validators.minLength(500), Validators.maxLength(3000)]);
		}
		this.dischargeForm.get('amount_piu').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('amount_others').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('price_ticket').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('expenses_by_financing').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('scope_project').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('comunication_scope').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('employment_generated').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('reinvest_project').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('destination_income').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('other_destination_income').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('comment_importance').updateValueAndValidity({emitEvent: false});
		this.dischargeForm.get('comment_suggestion').updateValueAndValidity({emitEvent: false});
	}
	/**
	 * validar todos los campos del formulario
	 *
	 * @param      {FormGroup}  formGroup  formulario de descargo
	 */
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched();
		});
	}
	/**
	 * envio de datos final
	 * del descargo
	 */
	enviarN(){
		this.sending = true;
		this.aOrRequired('required');
		this.dischargeForm.get('update').setValue('submit');
		this.dischargeForm.get('update').updateValueAndValidity({emitEvent:false});
		if(this.dischargeForm.valid){
			this.submit.emit(this.dischargeForm.value);
		}else{
			this.toastr.error('Por favor, verifique detalladamente los campos del formulario.', '¡Advertencia!');
			this.validateAllFormFields(this.dischargeForm);
			this.sending = false
		}
	}
	/**
	 * agregar opciones de acuerdo al caso
	 */
	setOptions(){
		switch (this.type) {
			case "01":
				this.options.op1 = '01';
				this.options.op2 = '01';
				this.options.op3 = '01';
				this.options.op5 = '01';				
				break;
			case "02":
				this.options.op1 = '02';
				this.options.op2 = '02';
				this.options.op3 = '02';
				break;
			case "03":
			case "04":
				this.options.op1 = '03';
				this.options.op2 = '03';
				break;			
			default:
				break;
		}
	}
	/**
	 * regresar a listado de descargos
	 */
	goDescargos(){
		this.back.emit(true);
	}
}
