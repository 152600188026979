import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, from  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';

@Component({
	selector: 'kt-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
	review: boolean;
	preview: boolean = false;
	hash: string;
	total: number = 0;
	regConfig: FieldConfig[] = [];
	field: FieldConfig;
	group: FormGroup;
	formTab: FormGroup;
	columns: any[] = [];
	element: any[] = [];
	data: any[]=[];
	displayedColumns = this.columns.map(c => c.columnDef);
	buttonModal:string = 'Agregar';
	indexEdit:number = 0;
	modal: NgbModalRef;
	values: any = null;
	isTable: boolean = true;
	haveFiles: string[] = [];
	haveWait: boolean = false;
	toDeleteFiles: string[] =[];
	conditions: boolean[] = [];
	component: boolean[] = [];
	completeFiles: any[] = [];
	isProccessing: boolean = false;
	firstValue: any;
	maxRows: number;
	ready: boolean = false;
	aux = new BehaviorSubject<any>(null);
	constructor(
		private modalService: NgbModal, 
		private fb: FormBuilder, 
		private http: HttpClient, 
		private toastr: ToastrService,
	) { }
	ngOnInit() {
		this.maxRows = this.getMax();
		this.haveWait = this.viewWait();
		this.haveFiles = this.viewFiles();

		for(let o of this.field.form_fields){
			let col = {
				columnDef: o.fieldname,
				header: (o.alias)?o.alias:o.label,
				type: o.type,
				subtype: o.subtype,
				prepend: o.prepend,
				append: o.append,
				cell: (element: any) => `${(element[o.fieldname] !== null)?element[o.fieldname]:'-'}`
			};
			switch (o.type) {
				case "textbox":
					col.cell = (element: any)=>{
						let val: string = '';
						if(o.subtype == 'digits' || o.subtype == 'decimal'){
							val = this.toFormatNum(element[o.fieldname]);
						}else{
							val = element[o.fieldname];
						}
						return `${val}`;
					}
					break;
				case "textarea":
					col.cell = (element: any) => {
						if(!this.review){
							return ``;
						}else{
							return `${(element[o.fieldname])?element[o.fieldname]:'-'}`
						}
					};
					break;
				case "fileupload":
					col.cell = (element: any) => { 
						if(!this.review){
							return `${(!element[o.fieldname] || element[o.fieldname]=='No Corresponde' || element[o.fieldname]=='')?'No Corresponde':''}`;
						}else{
							return `${(!element[o.fieldname] || element[o.fieldname]=='No Corresponde' || element[o.fieldname]=='')?'No Corresponde':element[o.fieldname].url_file}`;
						}
					};
					break;
				case "select":
					if(o.subtype == 'select' && !o.search){
						col.cell = (element: any) => `${(element[o.fieldname])?(element[o.fieldname][0])?element[o.fieldname][0].text:'-':'-'}`;
					}
					if(o.subtype == 'select' && o.search){
						col.cell = (element: any) => `${(element[o.fieldname])?element[o.fieldname][0].text:'-'}`;
					}
					if(o.subtype == 'multiselect'){
						col.cell = (element: any) =>{
							let mv: string[] = [];
							if(element[o.fieldname]){
								element[o.fieldname].forEach(v=>{
									mv.push(`- ${v.text}`);
								});
							}
							return mv.toString().replace(/,/g, '\n');
						};	
					}					
					break;
				case "checkbox":
					col.cell = (element:any)=>{
						let mv: string[] = [];
						if(element[o.fieldname]){
							element[o.fieldname].forEach(v=>{
								mv.push(`- ${v.text}`);
							});
						}
						return mv.toString().replace(/,/g, '\n');
					};
					break;
				default:
					break;
			}
			this.columns.push(col);
		}
		this.displayedColumns.push('Nro');
		for(let c of this.columns){
			this.displayedColumns.push(c.columnDef);
		}
		if(this.field.config){
			if((this.field.config.edit || this.field.config.delete)&& !this.review){
				this.displayedColumns.push('Opciones');
			}
		}		
		if(this.field.defaultvalue){
			this.element = this.field.defaultvalue;
			if(this.isAutoCalculate()){
				this.calculeTotal();
			}
		}
		this.data = [...this.element];
	}
	openLarge(content) {
		this.ready = false;
		this.buttonModal = 'Agregar';
		if(this.maxRows == 0 || (this.maxRows != 0 && this.element.length < this.maxRows)){
			this.iniForm(this.regConfig);
		    this.modal = this.modalService.open(content, {
		        size: 'lg',
		        backdrop : 'static',
		        windowClass:'table_form',
		  		keyboard : false
		    });			
		}
		this.aux.next('load add');
        this.isProccessing = false;
    }
    submit(value: any) {}
    iniFormTab(event){
    	this.formTab = event;
    }
    addE(event){
    	event.preventDefault();
		event.stopPropagation();
		if(!this.preview){
		   	if(this.formTab.valid){
		   		this.isProccessing = true;
		   		if(this.haveFiles.length>0 && this.anyFileRequired() && this.isFilesPreloads()){
		   			this.sendToProccessFile();	   			
		   		}else{
		   			if(this.buttonModal=='Agregar'){
			    		this.element.push(this.prepare());
			   		}
			   		else{
						this.element[this.indexEdit] = this.prepare();
			   		}
			   		this.data = [...this.element];
			   		this.group.get(this.field.fieldname).setValue(this.element);
			   		if(this.isAutoCalculate()){
			   			this.calculeTotal();
			   		}
			   		this.modal.close();
			   		this.isProccessing = false;
		   		}
	    	}
	    	else{
	    		this.validateAllFormFields(this.formTab);
	    	}    	
		}
    }
    validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
	edit(index, element, mod){
		this.ready = false;
		this.buttonModal = 'Editar';
		this.indexEdit = index;
		this.iniForm(this.field.form_fields, element);
		this.modal = this.modalService.open(mod, {
            size: 'lg',
            backdrop : 'static',
            windowClass:'table_form',
      		keyboard : false
        });
		this.aux.next('load edit');
        this.isProccessing = false;
	}
	iniForm(field:FieldConfig[], values=null){
		let val:FieldConfig[]=[];
		for(let v of field){
			if(values!=null)
				v.defaultvalue=values[v.fieldname];
			else
				v.defaultvalue=null;
			val.push(v);
		}
		if(values!=null){
			this.regConfig = val;
		}
		else{
			this.resetForm();
			this.regConfig = this.field.form_fields;
		}
		this.formTab = this.createControl();
		this.toDeleteFiles = [];
		if(!this.haveWait){
			setTimeout(()=>{this.ready=true;this.updateConditions();},500);
			this.onChanges();
		}
	}
	delete(index, element){
		this.deleteFiles(this.haveFiles, element);
		this.element.splice(index,1);
		if(this.element.length==0){
			this.group.get(this.field.fieldname).reset();	
		}else{
			this.group.get(this.field.fieldname).setValue(this.element);
		}
		this.data = [...this.element];
		if(this.isAutoCalculate()){
			this.calculeTotal();
		}
		//this.group.get(this.field.fieldname).updateValueAndValidity();	
	}	
	createControl() {
		const group = this.fb.group({});
		this.regConfig.forEach(field => {
			switch (field.type) {
				case "html":
				case "button":
				case "title":
				case "rdate":
					return;
					break;
				default:
					let val:any = (field.defaultvalue && field.defaultvalue!=null)?field.defaultvalue:'';
					if(field.type=='select'){
						if((field.subtype=='select' && !field.search) || field.optionstype!='custom'){
							if(!field.conditionlogic)
								this.component[field.fieldname]=false;
							else{
								if(field.conditionlogic.logic!='show'){
									this.component[field.fieldname]=false;
								}
							}
						}
						if(typeof field.defaultvalue == 'object'){
							if(field.subtype=='select' && !field.search){
								val = null;
							}else{
								val = (field.defaultvalue)?field.defaultvalue:'';	
							}							
						}
						else{
							if(field.subtype=='select' && !field.search){
								val = null;
							}
							else
								val = (field.defaultvalue)?field.defaultvalue:'';
						}
					}
					if(field.type=='checkbox'){
						this.component[field.fieldname] = false;
						val = null;
					}
					const control = this.fb.control(
						val,
						this.bindValidations(field.validations || [])
					);
					group.addControl(field.fieldname, control);
					break;
			}
			if(field.conditionlogic){
				this.conditions[field.fieldname] = ((field.conditionlogic.logic!='show')?true:false);
			}else{
				if(field.required && field.subtype=='fileupload'){
					this.conditions[field.fieldname] = true;
				}
			}
		});
		return group;
	}
	resetForm(){
		if(this.formTab){
			this.formTab.reset();
			Object.keys(this.formTab.controls).forEach(field => {
				this.formTab.get(field).setValue('');
				this.formTab.get(field).updateValueAndValidity({emitEvent:false});
			});	
		}		
	}
	bindValidations(validations: any) {
		/*if (validations.length > 0) {
			const validList = [];
			validations.forEach(valid => {
				switch (valid.name) {
					case "required":
						validList.push(Validators.required);
						break;
					case "pattern":
						validList.push(Validators.pattern(valid.value));
						break;
					case "minlength":
						validList.push(Validators.minLength(valid.value));
						break;
					case "maxlength":
						validList.push(Validators.maxLength(valid.value));
						break;
					case "min":
						validList.push(Validators.min(valid.value));
						break;
					case "max":
						validList.push(Validators.max(valid.value));
						break;
					case "email":
						validList.push(Validators.email);
						break;
					case "minselect":
						validList.push(Validators.minLength(valid.value));
						break;
					case "maxselect":
						validList.push(Validators.maxLength(valid.value));
						break;
					default:
						// code...
						break;
				}
			});
			return Validators.compose(validList);
		}*/
		return null;
	}
	prepare(){
		this.field.form_fields.forEach(
			d=>{
				if(d.type == 'datepicker'){
					this.formTab.value[d.fieldname] = this.formTab.value[d.fieldname].format('DD/MM/YYYY');
				}
				if(d.type == 'fileupload' && d.conditionlogic){
					this.formTab.value[d.fieldname] = (this.formTab.value[d.fieldname])?this.formTab.value[d.fieldname]:'No Corresponde';	
				}
				if(d.subtype=='select' && !d.search){
					this.formTab.value[d.fieldname] = [this.formTab.value[d.fieldname]];
				}
			}
		);
		return this.formTab.value;
	}
	onChanges(): void{
		let element: FieldConfig[] = this.field.form_fields.filter(e=> {return e.conditionlogic});
		element.forEach((val, key)=> {
			val.conditionlogic.conditions.forEach((v, k)=> {
				this.formTab.get(v.fieldname).valueChanges.subscribe(f=>{
					this.updateConditions(val.fieldname);
				});
			});
		});
		let fileTable: FieldConfig[] = this.field.form_fields.filter(e=>{ return e.type==='fileupload'});
		fileTable.forEach(val=>{
			this.formTab.get(val.fieldname).valueChanges.subscribe(ft=>{
				if(val.defaultvalue != ft && ft !='added' && ft !='updateInTable' && typeof ft =='object' && ft){
					this.completeFiles.push(ft);
					if(this.completeFiles.length == this.haveFiles.length && this.completeFiles.length >0 && this.verefyCompletes() && !ft.toString().includes('delete')){
						this.saveChangeFiles();						
					}
				}
				else{
					if(ft){
						let check: string = ft.toString();
						if(check.includes('delete')){
							let df: string[] = check.split(' ');
							if(this.toDeleteFiles.indexOf(df[1]) == -1){
								this.toDeleteFiles.push(df[1]);
							}
						}
					}					
				}
			});
		});
	}
	updateConditions(fieldname: string = ''){
		this.setDelRequired();
		if(fieldname == ''){
			let formFields: FieldConfig[] = this.field.form_fields.filter(e=> { return e.conditionlogic });
			formFields.forEach((field, key)=>{
				this.conditions[field.fieldname] = (field.conditionlogic.logic!='show')?true:false;
				let cond: boolean;
				field.conditionlogic.conditions.forEach((c, key)=>{
					if(this.formTab.get(c.fieldname)){
						if(c.operator == 'birthdate'){
							if(this.formTab.get(c.fieldname).value && this.formTab.get(c.fieldname).value!=''){
								let dateYear;
								if(typeof this.formTab.get(c.fieldname).value == 'object'){
									dateYear = new Date().getFullYear() - parseInt(this.formTab.get(c.fieldname).value.format('YYYY'));
								}else{
									let date = new FormControl(new Date(this.formTab.get(c.fieldname).value.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
									dateYear = new Date().getFullYear() - parseInt(moment(date.value).format('YYYY'));
								}
								cond = (dateYear<18);
							}else{
								cond = false;
							}
						}else{
							let isS: boolean = this.isSelect(c.fieldname);
							let isM: boolean = this.isMSelect(c.fieldname);
							let isC: boolean = this.isCheckbox(c.fieldname);
							let fValue = this.formTab.get(c.fieldname).value;
							switch (field.conditionlogic.method) {
								case "and":
									if(c.operator == '=='){
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond && (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = cond && (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}								
									}
									else{
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond && (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = cond && (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}									
									}
									break;
								case "or":
									if(c.operator == '=='){
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond || (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = cond || (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}								
									}
									else{
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond || (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = cond || (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}								
									}
									break;
								default:
									// code...
									break;
							}
						}
					}				
				});
				if(field.conditionlogic.logic == 'hidden'){
					cond = !cond;
				}
				if(this.conditions[field.fieldname] != cond || true){
					this.conditions[field.fieldname] = cond;
					this.conditionLogic(field.fieldname, this.conditions[field.fieldname]);
				}
			});	
		}
		else{
			let field: FieldConfig = this.field.form_fields.filter(e=> { return e.fieldname === fieldname})[0];
			let cond: boolean;
			field.conditionlogic.conditions.forEach((c, key)=>{
				if(this.formTab.get(c.fieldname)){
					if(c.operator == 'birthdate'){
						if(this.formTab.get(c.fieldname).value && this.formTab.get(c.fieldname).value!=''){
							let dateYear;
							if(typeof this.formTab.get(c.fieldname).value == 'object'){
								dateYear = new Date().getFullYear() - parseInt(this.formTab.get(c.fieldname).value.format('YYYY'));
							}else{
								let date = new FormControl(new Date(this.formTab.get(c.fieldname).value.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
								dateYear = new Date().getFullYear() - parseInt(moment(date.value).format('YYYY'));
							}
							cond = (dateYear<18);
						}else{
							cond = false;
						}
					}else{
						let isS: boolean = this.isSelect(c.fieldname);
						let isM: boolean = this.isMSelect(c.fieldname);
						let isC: boolean = this.isCheckbox(c.fieldname);
						let fValue = this.formTab.get(c.fieldname).value;
						switch (field.conditionlogic.method) {
							case "and":
								if(c.operator == '=='){
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond && fValue == c.value;
										}
										else{
											if(isS){
												cond = cond && (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}								
								}
								else{
									if(key==0){
										if(!isS && !isM && !isC){
											cond = fValue != c.value;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond && (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = cond && (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}									
								}	
								break;
							case "or":
								if(c.operator == '=='){
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond || (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = cond || (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}								
								}
								else{
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond || (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = cond || (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}								
								}	
								break;
							default:
								// code...
								break;
						}
					}
				}				
			});
			if(field.conditionlogic.logic == 'hidden'){
				cond = !cond;
			}
			if(this.conditions[field.fieldname] != cond){
				this.conditions[field.fieldname] = cond;
				this.conditionLogic(field.fieldname, this.conditions[field.fieldname]);
			}
		}	
	}
	conditionLogic(fieldname:string, status:boolean){
		let field: FieldConfig = this.field.form_fields.filter(e=>{return e.fieldname===fieldname})[0];
		if(field.required){
			const rules = [];
			field.validations.forEach(v=>{
				switch (v.name) {
					case "required":
						if(status){
							rules.push(Validators.required);
						}
						break;
					case "pattern":
						rules.push(Validators.pattern(v.value));
						break;
					case "minlength":
						rules.push(Validators.minLength(v.value));
						break;
					case "maxlength":
						rules.push(Validators.maxLength(v.value));
						break;
					case "min":
						rules.push(Validators.min(v.value));
						break;
					case "max":
						rules.push(Validators.max(v.value));
						break;
					case "email":
						rules.push(Validators.email);
						break;
					default:
						// code...
						break;
				}
			});
			if(!status){
				this.formTab.get(fieldname).reset();
			}
			this.formTab.get(fieldname).setValidators(rules);
			this.formTab.get(fieldname).updateValueAndValidity({emitEvent: true});
		}
	}
	viewFiles(): string []{
		let result = [];
		this.field.form_fields.forEach(val=>{
			if(val.type=='fileupload'){
				result.push(val.fieldname);
			}
		});
		return result;
	}
	viewWait(): boolean{
		let r: boolean = false;
		if(this.field.form_fields.filter(f=>{ return (f.subtype=='select' && !f.search)||(f.type=='select' && f.optionstype!='custom')||f.type=='checkbox'; }).length>0){
			r = true;
		}
		return r;
	}
	sendToProccessFile(){
		this.haveFiles.forEach(v=>{
			this.formTab.get(v).setValue('updateInTable');
			this.formTab.get(v).updateValueAndValidity();
			this.formTab.get(v).reset('');
			this.formTab.get(v).updateValueAndValidity();
		});
	}
	saveChangeFiles(){
		this.formTab.updateValueAndValidity();
		if(this.formTab.valid){
			if(this.buttonModal=='Agregar'){
	    		this.element.push(this.prepare());
	   		}
	   		else{
				this.element[this.indexEdit] = this.prepare();
	   		}
	   		this.data = [...this.element];
			this.group.get(this.field.fieldname).setValue(this.element);
			//this.group.get(this.field.fieldname).updateValueAndValidity();
	   		this.completeFiles = [];
	   		this.deleteFiles();
	   		this.modal.close();
	   		this.resetForm();
		}else{
			this.validateAllFormFields(this.formTab);
		}
		this.isProccessing = false;
	}
	verefyCompletes(): boolean{
		let r: boolean = false;
		this.completeFiles.forEach(v=>{
			if(typeof v =='object'){
				r = true;
				return;
			}
		});
		return r;
	}
	anyFileRequired(): boolean{
		let r: boolean = false;
		this.haveFiles.forEach(v=>{
			if(this.conditions[v]){
				r = true;
				return;
			}
		});
		return r;
	}
	isFilesPreloads(): boolean{
		let r: boolean = false;
		this.haveFiles.forEach(v=>{
			if(typeof this.formTab.get(v).value != 'object' && this.conditions[v]){
				r = true;
				return;
			}
		});
		return r;
	}
	deleteFiles(button: string[] = [], elemet: any[]=[]){
		if(button.length==0){
			this.toDeleteFiles.forEach(d=>{
				let resp:Observable<any> = this.http.delete(d);
				resp.subscribe(
					data=>{},
		      		error=>{
		      			//this.catchError(error.error);
		      		},
				);
			});	
		}else{
			button.forEach(f=>{
				if(typeof elemet[f] == 'object'){
					let apiUrl: string = `${environment.apiUrl}/files/${elemet[f].unique_code}`;
					let resp:Observable<any> = this.http.delete(apiUrl);
					resp.subscribe(
						data=>{},
			      		error=>{
			      			//this.catchError(error.error);
			      		},
					);
				}				
			});
		}		
	}
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	getMax(): number{
		let max: number = 0;
		if(this.field.validations){
			let val = this.field.validations.filter(e=>{ return e.name==='maxrows';});
			if(val.length == 1){
				max = val[0].value;
			}
		}
		return max;
	}
	getTextOption(fieldname: string, val: string): string{
		let t = '';
		let field: FieldConfig = this.regConfig.filter(e=>{return e.fieldname===fieldname})[0];
		let r = field.options.filter(e=>{return e.value === val })[0];
		if(r){
			t = r.text;
		}
		return t;
	}
	isSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.field.form_fields.filter(e=>{ return e.fieldname===fieldname && e.subtype==='select' && !e.search });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	isMSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.field.form_fields.filter(e=>{ return e.fieldname===fieldname && (e.search || e.subtype==='multiselect') });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	isCheckbox(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.field.form_fields.filter(e=>{ return e.fieldname===fieldname && e.type==='checkbox' });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	urlGoogle(url: string): string{
		if(url!='')
			return `https://docs.google.com/gview?url=${url}&embedded=true`;
		else
			return ``;
	}
	setReady(event){
		this.component[event] = true;
		this.isAllReady();
	}
	isAllReady(){
		let r:boolean = true;
		Object.keys(this.component).forEach(field => {
			if(this.component[field] === false){
				r = false;
				return;
			}
		});		
		if(r){
			if(this.component.length<=2){
				setTimeout(()=>{
					this.ready = true;
					this.updateConditions();
					this.onChanges();			
				}, 500);
			}
			else{
				this.ready = true;
				this.updateConditions();
				this.onChanges();		
			}
		}
		return r;
	}
	setDelRequired(){
		this.regConfig.forEach(val=>{
			const rules = []
			if(val.validations){
				val.validations.forEach(v=>{
					switch (v.name) {
						case "required":
							if(!val.conditionlogic){
								rules.push(Validators.required);
							}else{
								if(val.conditionlogic.logic!='show')
									rules.push(Validators.required);
							}
							break;
						case "pattern":
							rules.push(Validators.pattern(v.value));
							break;
						case "min":
							rules.push(Validators.min(v.value));
							break;
						case "max":
							rules.push(Validators.max(v.value));
							break;
						case "email":
							rules.push(Validators.email);
							break;
						case "minlength":
						case "minselect":
						case "minrows":
							rules.push(Validators.minLength(v.value));
							break;
						case "maxlength":
						case "maxselect":
						case "maxrows":
							rules.push(Validators.maxLength(v.value));
							break;
						default:
							// code...
							break;
					}
				});
			}
			this.formTab.get(val.fieldname).setValidators(rules);
			this.formTab.get(val.fieldname).updateValueAndValidity({emitEvent: false});
		});
	}
	toFormatNum(value): string{
    	let r = Number(value).toLocaleString('en-GB');
    	return r;
    }
    /*---autocalculate---*/
    isAutoCalculate(): boolean{
    	let r = false;
    	if(this.field.autocalculate && this.field.autocalculate!=''){
	    	if(this.field.form_fields.filter(v=>{return v.fieldname === this.field.autocalculate}).length>0){
	    		r = true;
	    	}
    	}
    	return r;
    }
    calculeTotal(){
    	this.total = 0;
    	this.element.forEach(v=>{
    		this.total += parseInt(v[this.field.autocalculate]);
    	});
    }
    goToUrl(url: string){
    	if(url.indexOf('http:')>-1 || url.indexOf('https:')>-1 ){
	    	window.open(url, '_blank');
    	}
    }
}
