import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { FieldConfig, Validator } from "../../field.interface";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import * as moment from 'moment';

@Component({
	selector: 'kt-form-preview',
	templateUrl: './form-preview.component.html',
	styleUrls: ['./form-preview.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class FormPreviewComponent implements OnInit, AfterViewInit {
	@Input() id: string;
	fields: FieldConfig[];
	form: FormGroup;
	conditions: boolean[] = [];
	component: boolean[] = [];
	ready: boolean = false;
	isSend: boolean =false;
	haveWait: boolean;
	aux = new BehaviorSubject<any>(null);

	constructor(
		private fb: FormBuilder, 
		private toastr: ToastrService, 
		private http: HttpClient,
	) { }
	/**
	 * inicializa datos del componente
	 */
	ngOnInit() {
		this.getForm(this.id);
	}
	/**
	 * dispara eventos de estado del componente
	 */
	ngAfterViewInit(){
		if(!this.haveWait){
			setTimeout(()=>{
				this.ready = true;
				this.updateConditions();
				this.onChanges();			
			},800);			
		}
	}
	/**
	 * obtiene datos del formulario que se desea previsualizar
	 * @param {string} id id de formulario
	 */
	getForm(id: string){
		let apiUrl = `${environment.apiUrl}/formbuilder/forms/${id}`;
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Accept':'application/json',
		  	})
		};
		let resp:Observable<any> = this.http.get(apiUrl,httpOptions);
		resp.subscribe(
			response=>{
				let r = response.data;
				this.fields = r.schema_web;
				this.form = this.createControl();
				//this.onChanges();
				this.aux.next('ready form');
			},
      		error=>{
      			this.catchError(error.error);
      		},
		);
	}
	/**
	 * crea formulario dinamico
	 */
	createControl() {
		this.haveWait = this.viewWait();
		const group = this.fb.group({});
		this.fields.forEach(field => {
			switch (field.type) {
				case "html":
				case "button":
				case "title":
				case "rdate":
					return;
					break;
				default:
					let val:any = (field.defaultvalue && field.defaultvalue!=null)?field.defaultvalue:'';
					if(field.type=='select'){
						if((field.subtype=='select' && !field.search) || field.optionstype!='custom'){
							if(!field.conditionlogic)
								this.component[field.fieldname]=false;
							else{
								if(field.conditionlogic.logic!='show'){
									this.component[field.fieldname]=false;
								}
							}
						}
						if(typeof field.defaultvalue == 'object'){
							if(field.subtype=='select' && !field.search){
								val = null;
							}else{
								val = (field.defaultvalue)?field.defaultvalue:'';	
							}							
						}
						else{
							if(field.subtype=='select' && !field.search)
								val = null;
							else
								val = (field.defaultvalue)?field.defaultvalue:'';
						}
					}
					if(field.type=='checkbox'){
						this.component[field.fieldname] = false;
						val = null;
					}
					const control = this.fb.control(
						val,
						this.bindValidations(field.validations || [])
					);
					group.addControl(field.fieldname, control);
					break;
			};
			if(field.conditionlogic){
				this.conditions[field.fieldname] = ((field.conditionlogic.logic!='show')?true:false);
			}
		});
		group.addControl('terms', this.fb.control(null));
		return group;
	}
	/**
	 * crea validaciones para elementos del formulario dinamico
	 * @param {any} validations tipo de validacion
	 */
	bindValidations(validations: any) {
		if (validations.length > 0) {
			const validList = [];
			validations.forEach(valid => {
				switch (valid.name) {
					case "required":
						validList.push(Validators.required);
						break;
					case "pattern":
						validList.push(Validators.pattern(valid.value));
						break;
					case "minlength":
					case "minselect":
					case "minrows":
						validList.push(Validators.minLength(valid.value));
						break;
					case "maxlength":
					case "maxselect":
					case "maxrows":
						validList.push(Validators.maxLength(valid.value));
						break;
					case "min":
						validList.push(Validators.min(valid.value));
						break;
					case "max":
						validList.push(Validators.max(valid.value));
						break;
					case "email":
						validList.push(Validators.email);
						break;
					default:
						// code...
						break;
				}
			});
			return Validators.compose(validList);
		}
		return null;
	}
	/**
	 * validar todos los elementos de formulario
	 * @param {FormGroup} formGroup formulario
	 */
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched();
		});
	}
	/**
	 * dispara monitorizacion de los cambios 
	 * que producen elementos que esten relacionados con 
	 * condiciones logicas que se deben cumplir
	 */
	onChanges(): void{
		let element: FieldConfig[] = this.fields.filter(e=> {return e.conditionlogic});
		element.forEach((val, key)=> {
			val.conditionlogic.conditions.forEach((v, k)=> {
				this.form.get(v.fieldname).valueChanges.subscribe(f=>{
					this.updateConditions(val.fieldname);
				});
			});
		});
	}
	/**
	 * actualiza el estado de las condiciones logicas
	 * @param {string = ''} fieldname nombre del elemento que produce el cambio
	 */
	updateConditions(fieldname: string = ''){
		if(fieldname == ''){
			let formFields: FieldConfig[] = this.fields.filter(e=> { return e.conditionlogic });
			formFields.forEach((field, key)=>{
				this.conditions[field.fieldname] = (field.conditionlogic.logic!='show')?true:false;
				let cond: boolean;
				field.conditionlogic.conditions.forEach((c, key)=>{
					if(this.form.get(c.fieldname)){
						if(c.operator == 'birthdate'){
							if(this.form.get(c.fieldname).value && this.form.get(c.fieldname).value!=''){
								let dateYear;
								if(typeof this.form.get(c.fieldname).value == 'object'){
									dateYear = new Date().getFullYear() - parseInt(this.form.get(c.fieldname).value.format('YYYY'));
								}else{
									let date = new FormControl(new Date(this.form.get(c.fieldname).value.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
									dateYear = new Date().getFullYear() - parseInt(moment(date.value).format('YYYY'));
								}
								cond = (dateYear<18);
							}else{
								cond = false;
							}
						}else{
							let isS: boolean = this.isSelect(c.fieldname);
							let isM: boolean = this.isMSelect(c.fieldname);
							let isC: boolean = this.isCheckbox(c.fieldname);
							let fValue = this.form.get(c.fieldname).value;
							switch (field.conditionlogic.method) {
								case "and":
									if(c.operator == '=='){
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond && fValue == c.value;
											}
											else{
												if(isS){
													cond = cond && (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}								
									}
									else{
										if(key==0){
											if(!isS && !isM && !isC){
												cond = fValue != c.value;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond && (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = cond && (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}									
									}	
									break;
								case "or":
									if(c.operator == '=='){
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond || (fValue)?fValue == c.value:false;
											}
											else{
												if(isS){
													cond = cond || (fValue)?fValue.value == c.value:false;
												}
												if(isM)
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												if(isC){
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
												}
											}
										}								
									}
									else{
										if(key==0){
											if(!isS && !isM && !isC){
												cond = (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}else{
											if(!isS && !isM && !isC){
												cond = cond || (fValue)?fValue != c.value:false;
											}
											else{
												if(isS){
													cond = cond || (fValue)?fValue.value != c.value:false;
												}
												if(isM)
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												if(isC){
													cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
												}
											}
										}								
									}	
									break;
								default:
									break;
							}
						}
					}				
				});
				if(field.conditionlogic.logic == 'hidden'){
					cond = !cond;
				}
				if(this.conditions[field.fieldname] != cond || true){
					this.conditions[field.fieldname] = cond;
					this.conditionLogic(field.fieldname, this.conditions[field.fieldname]);
				}
			});	
		}
		else{
			let field: FieldConfig = this.fields.filter(e=> { return e.fieldname === fieldname})[0];
			let cond: boolean;
			field.conditionlogic.conditions.forEach((c, key)=>{
				if(this.form.get(c.fieldname)){
					if(c.operator == 'birthdate'){
						if(this.form.get(c.fieldname).value && this.form.get(c.fieldname).value!=''){
							let dateYear;
							if(typeof this.form.get(c.fieldname).value == 'object'){
								dateYear = new Date().getFullYear() - parseInt(this.form.get(c.fieldname).value.format('YYYY'));
							}else{
								let date = new FormControl(new Date(this.form.get(c.fieldname).value.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
								dateYear = new Date().getFullYear() - parseInt(moment(date.value).format('YYYY'));
							}
							cond = (dateYear<18);
						}else{
							cond = false;
						}
					}else{
						let isS: boolean = this.isSelect(c.fieldname);
						let isM: boolean = this.isMSelect(c.fieldname);
						let isC: boolean = this.isCheckbox(c.fieldname);
						let fValue = this.form.get(c.fieldname).value;
						switch (field.conditionlogic.method) {
							case "and":
								if(c.operator == '=='){
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond && fValue == c.value;
										}
										else{
											if(isS){
												cond = cond && (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}								
								}
								else{
									if(key==0){
										if(!isS && !isM && !isC){
											cond = fValue != c.value;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond && (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = cond && (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}									
								}	
								break;
							case "or":
								if(c.operator == '=='){
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond || (fValue)?fValue == c.value:false;
										}
										else{
											if(isS){
												cond = cond || (fValue)?fValue.value == c.value:false;
											}
											if(isM)
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											if(isC){
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
											}
										}
									}								
								}
								else{
									if(key==0){
										if(!isS && !isM && !isC){
											cond = (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}else{
										if(!isS && !isM && !isC){
											cond = cond || (fValue)?fValue != c.value:false;
										}
										else{
											if(isS){
												cond = cond || (fValue)?fValue.value != c.value:false;
											}
											if(isM)
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											if(isC){
												cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
											}
										}
									}								
								}	
								break;
							default:
								break;
						}
					}
				}				
			});
			if(field.conditionlogic.logic == 'hidden'){
				cond = !cond;
			}
			if(this.conditions[field.fieldname] != cond){
				this.conditions[field.fieldname] = cond;
				this.conditionLogic(field.fieldname, this.conditions[field.fieldname]);
			}			
		}	
	}
	/**
	 * cambia el estado de un elemento dependiente de una condicion logica
	 * @param {string}  fieldname nombre del elemento
	 * @param {boolean} status    valor de condicion logica
	 */
	conditionLogic(fieldname: string, status: boolean){
		this.isSend = true;
		let field: FieldConfig = this.fields.filter(e=>{return e.fieldname===fieldname})[0];
		if(field.required){
			const rules = [];
			field.validations.forEach(v=>{
				switch (v.name) {
					case "required":
						if(status){
							rules.push(Validators.required);
						}
						break;
					case "pattern":
						rules.push(Validators.pattern(v.value));
						break;
					case "minlength":
						rules.push(Validators.minLength(v.value));
						break;
					case "maxlength":
						rules.push(Validators.maxLength(v.value));
						break;
					case "min":
						rules.push(Validators.min(v.value));
						break;
					case "max":
						rules.push(Validators.max(v.value));
						break;
					case "email":
						rules.push(Validators.email);
						break;
					case "minselect":
					case "minrows":
						rules.push(Validators.minLength(v.value));
						break;
					case "maxselect":
					case "maxrows":
						rules.push(Validators.maxLength(v.value));
						break;
					default:
						// code...
						break;
				}
			});
			if(!status){
				if(field.type!='fileupload'){
					this.form.get(fieldname).reset();
				}
			}
			this.form.get(fieldname).setValidators(rules);
			this.form.get(fieldname).updateValueAndValidity({emitEvent:false});
		}
		this.isSend = false;
		let time = new Date();
		this.aux.next(`${time} changes logic.`);
	}
	/**
	 * determina si un elemento es select simple
	 * @param  {string}  fieldname nombre del elemento
	 * @return {boolean}           valor de verdad
	 */
	isSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && e.subtype==='select' && !e.search });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * determina si un elemento es select multiple
	 * @param  {string}  fieldname nombre del elemento
	 * @return {boolean}           valor de verdad
	 */
	isMSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && (e.search || e.subtype==='multiselect') });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * determina si un elemento es checkbox
	 * @param  {string}  fieldname nombre del elemento
	 * @return {boolean}           valor de verdad
	 */
	isCheckbox(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && e.type==='checkbox' });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * establece que un elemento esta listo para ser presentado
	 * @param {any} event elemento que emite estado
	 */
	setReady(event){
		this.component[event] = true;
		this.isAllReady();
	}
	/**
	 * verifica si todos los elementos estan listos 
	 * para ser presentados en el formulario
	 */
	isAllReady(){
		let r:boolean = true;
		Object.keys(this.component).forEach(field => {
			if(this.component[field] === false){
				r = false;
				return;
			}
		});		
		if(r){
			this.ready = true;
			this.updateConditions();
			this.onChanges();
		}
		return r;
	}
	/**
	 * muestra ventana de espera antes de presentar el componente
	 * @return {boolean} valor de verdad
	 */
	viewWait(): boolean{
		let r: boolean = false;
		if(this.fields.filter(f=>{ return (f.subtype=='select' && !f.search)||(f.type=='select' && f.optionstype!='custom')||f.type=='checkbox'; }).length>0){
			r = true;
		}
		return r;
	}
	/**
	 * manejo de errores
	 * @param {error} e error producido por peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
}
