import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
	selector: 'kt-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, AfterViewInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	inTable: boolean = false;
	options: options[];
	multiSettings = {
		singleSelection: false,
		idField: 'value',
		textField: 'text',
		selectAllText: 'Seleccionar Todo',
		unSelectAllText: 'Limpiar Selección',
		searchPlaceholderText: 'Buscar',
		allowSearchFilter: false,
		disabled:true
	};
	aux = new BehaviorSubject<any>(null);
	@Output() ready: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('mS') message: NgMultiSelectDropDownModule;

	constructor(
		private http: HttpClient, 
		private toastr: ToastrService, 
		private cdRef:ChangeDetectorRef
	) { }
	/**
	 * inicializar opciones
	 */
	ngOnInit() {
		this.initOptions(this.field.optionstype);
		if(this.field.subtype=='select' && !this.field.search && this.field.readonly){
			this.group.get(this.field.fieldname).disable();
		}
	}
	/**
	 * deteccion de cambios
	 */
	ngAfterViewInit(){
		this.cdRef.detectChanges();
		if(this.message && (this.field.subtype!='select' || this.field.search) && this.field.readonly){
			this.aux.next('disable multi select 1');
			this.message['disabled']=true;
			this.aux.next('disable multi select 2');
		}
		if(this.field.subtype=='select' && !this.field.search && this.field.optionstype=="custom"){
			this.setValue();
		}
	}
	/**
	 * asignacion de opciones en el select
	 *
	 * @param      {string}  type    tipo de opcion
	 */
	initOptions(type: string){
		if(!this.review){
			switch (type) {
				case "custom":
					this.options = [];
					if(this.field.options){
						this.field.options.forEach(val=>{
							let o = {
								text: val.text,
								value: val.value
							};
							this.options.push(o);
						});
					}
					break;
				default:
					this.getOptions(type);
					break;
			}
			if(this.field.search){
				this.multiSettings['allowSearchFilter'] = true;
				if(this.field.subtype=='select'){
					this.multiSettings['limitSelection'] = 1;
				}			
			}
			if(this.field.readonly){
				this.multiSettings['disabled'] = true;
			}
			
		}else{
			this.options = [];
		}
	}
	/**
	 * agrega valor por defecto en el select
	 */
	setValue(){
		if(!this.review){
			if(this.field.defaultvalue && this.field.defaultvalue!=''){
				let o;
				if(!this.inTable){
					o = this.options.filter(v=>{
						return v.value === this.field.defaultvalue[0].value;
					})[0];
				}else{
					o = this.options.filter(v=>{
						return v.value === this.field.defaultvalue[0].value;
					})[0];
				}
				this.group.get(this.field.fieldname).setValue(o);
				this.group.get(this.field.fieldname).updateValueAndValidity({emitEvent:false});
				this.onReady();
			}else{
				this.group.get(this.field.fieldname).setValue('');
				this.group.get(this.field.fieldname).updateValueAndValidity({emitEvent:false});
				this.onReady();
			}
		}
	}
	/**
	 * Gets the options.
	 *
	 * @param      {string}  type    tipo de api para obtener opciones
	 */
	getOptions(type: string){
		let apiUrl = environment.apiUrl;
		switch (type) {
			case "catalog":
				apiUrl += `/a/catalog_items/code/${this.field.options}?include=catalog_items`;
				break;
			case "countries":
				apiUrl += `/countries?limit=1000`;
				break;
			case "cities":
				apiUrl += `/countries/code/${this.field.options}`;
				break;
			default:
				// code...
				break;
		}
		let resp:Observable<any> = this.http.get(apiUrl);
		resp.subscribe(
			response=>{
				let opt: any[];
				switch (type) {
					case "catalog":
						opt = response.data.catalog_items.data;
						//opt = [];
						break;
					case "countries":
						opt = response.data;
						break;				
					case "cities":
						opt = response.data.states.data;
						break;
					default:
						opt = [];
						break;
				}
				this.options = [];
				opt.forEach(c=>{
					this.options.push({text: c.name, value: c.id});
				});
				if(this.field.subtype=='select' && !this.field.search){
					this.setValue();
				}else{
					this.ready.emit(this.field.fieldname);
				}
				this.aux.next(`load options of ${this.field.fieldname}`);
			},
      		error=>{
      			this.catchError(error);
      		},
		);
	}
	/**
	 * detecta cambios en multiselect
	 */
	changeMulti(){
		this.group.get(this.field.fieldname).markAsTouched();
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<error>}  e  error producido por perticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * obtiene el texto de la opcion
	 *
	 * @param      {<any>}  value   opcion
	 */
	getText(value): string{
		let v = (value && value!='')?value[0]:'';
		return (v.text)?v.text:'-';
	}
	/**
	 * indica cuando el componente esta listo
	 */
	onReady(){
		this.ready.emit(this.field.fieldname);
	}
}
/**
 * interface de opcion
 * del select
 */
export interface options{
	text?: string;
	value?: string;
	selected?: boolean;
}