// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom, catchError } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserLoaded, UserRequested, UserConfirmed, RefreshToken } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded, isUserConfirmed, currentAuthToken, currentRefreshAuthToken } from '../_selectors/auth.selectors';
import { User } from '../index';


@Injectable()
export class AuthEffects {
    @Effect({dispatch: false})
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            localStorage.setItem('Token', action.payload.authToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
            localStorage.setItem('expire', action.payload.expire.toString());
            localStorage.setItem('confirmed', action.payload.confirmed.toString());
            this.store.dispatch(new UserRequested());
        }),
    );

    @Effect({dispatch: false})
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            if(localStorage.getItem('Token')){
                localStorage.removeItem('Token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('expire');
                localStorage.removeItem('confirmed');
            }
            let url = this.router.url.toString();
            if(url.search('auth')===-1){
			    this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}});
            }
        })
    );

    @Effect({dispatch: false})
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            localStorage.setItem('Token', action.payload.authToken);
        })
    );

    @Effect({dispatch: false})
    loadUser$ = this.actions$
    .pipe(
        ofType<UserRequested>(AuthActionTypes.UserRequested),
        withLatestFrom(this.store.pipe(select(isUserLoaded))),
        filter(([action, _isUserLoaded]) => !_isUserLoaded),
        mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
        tap(_user => {
            if (_user) {
                this.store.dispatch(new UserLoaded({ user: _user }));
            } else {
                this.store.dispatch(new Logout());
            }
        })
      );

    @Effect({dispatch: false})
    confirmedUser$ = this.actions$
    .pipe(
        ofType<UserConfirmed>(AuthActionTypes.UserConfirmed),
        tap((action) => {
            localStorage.removeItem('confirmed');
            localStorage.setItem('confirmed', action.payload.confirmed.toString());
            this.router.navigate(['/']);
        })
      );

    @Effect({dispatch: false})
    refreshToken$ = this.actions$
    .pipe(
        ofType<RefreshToken>(AuthActionTypes.RefreshToken),
        tap((action)=>{
            localStorage.removeItem('Token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('expire');
            localStorage.setItem('Token', action.payload.authToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
            localStorage.setItem('expire', action.payload.expire.toString());
        })
      );

    @Effect()
    init$: Observable<Action> = defer(() => {
        const userToken = localStorage.getItem('Token');
        const userRefreshToken = localStorage.getItem('refreshToken');
        const userExpire = parseInt(localStorage.getItem('expire'));
        const userConfirmed = JSON.parse(localStorage.getItem('confirmed'));
        let observableResult = of({type: 'NO_ACTION'});
        if (userToken) {
            observableResult = of(new Login({  authToken: userToken, refreshToken: userRefreshToken, expire: userExpire, confirmed: userConfirmed}));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>) {

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
}
