import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { RouterOutlet, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'kt-table-convocatories',
	templateUrl: './table-convocatories.component.html',
	styleUrls: ['./table-convocatories.component.scss']
})

export class TableConvocatoriesComponent implements OnInit {
	@Input() type: string;
	@Input() title: string;
	@Input() status: string;
	@Output() isReady: EventEmitter<any> = new EventEmitter();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	public url:string = environment.apiUrl;
	public conv: Convocatorie[]=[];
	displayedColumns = ['area', 'code', 'name', 'perfil', 'fecha', 'url', 'opcion'];
	_dataSource: MatTableDataSource<Convocatorie> = new MatTableDataSource(this.conv);
	dutchRangeLabel = (page: number, pageSize: number, length: number) => {
	  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
	  length = Math.max(length, 0);
	  const startIndex = page * pageSize;
	  const endIndex = startIndex < length ?
	      Math.min(startIndex + pageSize, length) :
	      startIndex + pageSize;
	  return `${startIndex + 1} - ${endIndex} de ${length}`;
	}

	constructor(
		private http: HttpClient, 
		private router: Router, 
		private toastr: ToastrService
	) {}
	/**
	 * inicializa la pagina
	 */
	ngOnInit() {
		this.getConvocatories(this.url, this.status, this.type);
	}
	/**
	 * busqueda en la tabla
	 *
	 * @param      {string}  filterValue  valor buscado
	 */
	_applyFilter(filterValue: string) {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this._dataSource.filter = filterValue;
	}
	/**
	 * obtiene los datos de una convocatoria
	 *
	 * @param      {string}  URL     url de api base
	 * @param      {string}  STATUS  estado de convocatorias
	 * @param      {string}  TYPE    tipo de convocatorias
	 */
	getConvocatories(URL:string, STATUS:string='current', TYPE:string='proyectos'){
		URL = URL+'/a/convocatories?class='+STATUS+'&search=type:'+TYPE+'&limit=100';
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/json',
		  	})
		};
		let resp:Observable<any> = this.http.get(URL,httpOptions);
		resp.subscribe(
			data=>{
				for(let d of  data.data){
					let c ={
						id: d.id,
						code: d.code,
						name: d.name,
						version: d.version,
						area_intervention: d.area_intervention,
						line_intervention: d.line_intervention,
						perfil_pn: d.perfil_pn,
						perfil_pj: d.perfil_pj,
						perfil_ac: d.perfil_ac,
						fecha: d.finish_postulation_date,
						url_more: d.url_more,
						perfil: d.perfil_pn + d.perfil_pj + d.perfil_ac,
						area : (d.line_intervention)?`${d.area_intervention.data.name} ${d.line_intervention.data.name}`:d.area_intervention.data.name
					};
					this.conv.push(<Convocatorie>c);			
				}
				this._dataSource = new MatTableDataSource(this.conv);
				this.paginator._intl.itemsPerPageLabel = 'Convocatorias por Página';
				this.paginator._intl.firstPageLabel = 'Primer Página';
				this.paginator._intl.lastPageLabel = 'Ultima Página';
				this.paginator._intl.nextPageLabel = 'Siguiente';
				this.paginator._intl.previousPageLabel = 'Anterior';
				this.paginator._intl.getRangeLabel = this.dutchRangeLabel;
				this._dataSource.paginator = this.paginator;
				this._dataSource.sort = this.sort;
				this.isReady.emit(TYPE);
			},
      		error=>{
      			this.catchError(error);
      			this._dataSource = new MatTableDataSource(this.conv);
				this._dataSource.paginator = this.paginator;
				this._dataSource.sort = this.sort;
      		},
		);
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<error>}  e    error producido por una peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * Gets the range label.
	 *
	 * @param      {number}  page      pagina actual en la tabla
	 * @param      {number}  pageSize  numero de elementos en la tabla
	 * @param      {number}  length    total de elementos de la tabla
	 * @return     {string}  label de la paginacion.
	 */
	private getRangeLabel (page: number, pageSize: number, length: number): string {
	    if (length == 0 || pageSize == 0) {
	        return "0 de " + length;
	    }
	    length = Math.max(length, 0);
	    var startIndex = page * pageSize;
	    var endIndex = startIndex < length ?
	        Math.min(startIndex + pageSize, length) :
	        startIndex + pageSize;
	    return startIndex + 1 + " - " + endIndex + " de " + length;
	}
	/**
	 * ir a la pagina de incio de postulacion de una convocatoria
	 *
	 * @param      {string}  code    codigo de la convocatoria
	 * @param      {string}  name    nombre de la convocatoria
	 */
	convocatory(code: string, name: string){
		let cleanName = this.cleanName(name);
		this.router.navigateByUrl(`/convocatorias/${code}/paso-1/${cleanName}`);
	}
	/**
	 * limpia caracteres especiales de una cadena
	 *
	 * @param      {string}  name    nombre de convocatoria
	 * @return     {string} { cadena limpia de caracteres especiales }
	 */
	cleanName(name: string):string{
		let r = name.toLowerCase();
		r = r.replace(new RegExp(/\s/g),"-");
	    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
	    r = r.replace(new RegExp(/æ/g),"ae");
	    r = r.replace(new RegExp(/ç/g),"c");
	    r = r.replace(new RegExp(/[èéêë]/g),"e");
	    r = r.replace(new RegExp(/[ìíîï]/g),"i");
	    r = r.replace(new RegExp(/ñ/g),"n");                
	    r = r.replace(new RegExp(/[òóôõö]/g),"o");
	    r = r.replace(new RegExp(/œ/g),"oe");
	    r = r.replace(new RegExp(/[ùúûü]/g),"u");
	    r = r.replace(new RegExp(/[ýÿ]/g),"y");
	    r = r.replace(new RegExp(/\W/g),"-");
		return r;
	}
}
/**
 * interface de convocatoria
 * modelo de datos de una convocatoria en la tabla
 */
export interface Convocatorie{
	id: string;
	code:string;
	name:string;
	version?:any;
	area_intervention: any;
	line_intervention?: any;
	perfil_pn: number;
	perfil_pj: number;
	perfil_ac: number;
	fecha: Date;
	url_more: string;
	perfil: number;
	area: string;
}
