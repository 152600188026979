import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
	selector: 'kt-file-view',
	templateUrl: './file-view.component.html',
	styleUrls: ['./file-view.component.scss']
})

export class FileViewComponent implements OnInit, AfterViewInit {
	@Input() field: FieldConfig;
	@Input() type: string = 'principal';
	group: FormGroup;
	review: boolean;
	baseGoogle: string;
	wait = 3000;
	@ViewChild('iframe') iframe?: any;
	constructor() {
	}
	/**
	 * inicializa el valor de la 
	 * url del iframe utilizado
	 */
	ngOnInit() {
		if(this.field.defaultvalue && this.field.defaultvalue !=''){
			this.baseGoogle = `https://docs.google.com/gview?url=${this.field.defaultvalue.url_file}&embedded=true`;
		}
	}
	/**
	 * verifica si el iframe fue cargado
	 */
	ngAfterViewInit(){
		if(this.field.defaultvalue && this.field.defaultvalue !=''){
			this.frameStatus();
		}
	}
	/**
	 * verifica el estado del iframe
	 * hasta que este se carge
	 * y se presente en la pagina
	 */
	frameStatus(){
		setTimeout(()=>{
			if(this.iframe.nativeElement.contentDocument!= null){
				this.iframe.nativeElement.src = this.baseGoogle;
				this.frameStatus();
			}else{				
			}
		}, this.wait);
	}
}

