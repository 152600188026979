// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreviewComponent } from './views/pages/preview/preview.component';


const routes: Routes = [
	{	path: 'preview', 
		children:[
			{
				path:':id',
				component: PreviewComponent
			},
			{path: '', redirectTo: '/error/403', pathMatch: 'full'},
			{path: '**', redirectTo: '/error/403', pathMatch: 'full'}
		]
	},
	{path: 'auth', loadChildren: 'app/views/pages/auth/auth.module#AuthModule'},
	{path: '', loadChildren: 'app/views/themes/piu/theme.module#ThemeModule'},
	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
	//{path: '**', redirectTo: 'demo4/error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
