// Angular
import { Component, HostBinding, Input, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject  } from 'rxjs';

@Component({
	selector: 'kt-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, AfterViewInit {
	// Public properties
	// type of error template to be used, accepted values; error-v1 | error-v2 | error-v3 | error-v4 | error-v5 | error-v6
	@Input() type: string = 'error-v2';
	// full background image
	@Input() image: string;
	// error code, some error types template has it
	@Input() code: string = '404';
	// error title
	@Input() title: string;
	// error subtitle, some error types template has it
	@Input() subtitle: string;
	// error descriptions
	@Input() desc: string;
	// error url
	@Input() url: string;
	// return back button title
	@Input() return: string = 'Return back';

	@HostBinding('class') classes: string = 'kt-grid kt-grid--ver kt-grid--root';
	aux = new BehaviorSubject<any>(null);
	constructor(
		private router: Router,
	){
	}
	ngOnInit(){
		//console.log(this);
	}
	ngAfterViewInit(){
		setTimeout(()=>{this.aux.next('error produce');},200);		
	}
	goInit(){
		this.router.navigateByUrl('/postulaciones/borradores');
	}
}
