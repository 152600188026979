import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from "@angular/forms";
import * as moment from 'moment';
import { FieldConfig, Validator } from "../../field.interface";

@Component({
	selector: 'kt-review-form',
	templateUrl: './review-form.component.html',
	styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {
	@Input() fields: FieldConfig[] = [];
	@Input() values: any;
	@Input() status: string = '';
	@Output() complete: EventEmitter<any> = new EventEmitter<any>();
	@Output() back: EventEmitter<any> = new EventEmitter<any>();
	review:boolean = false;
	conditions: boolean[] = [];

	constructor() { }
	/**
	 * inicializar datos en el componente
	 */
	ngOnInit() {
		this.intReview();
	}
	/**
	 * inicializa vista del componente
	 * establece las condiciones logicas existentes
	 */
	intReview(){
		this.fields.forEach((val,index, array)=>{
			if(this.values[val.fieldname]){
				array[index].defaultvalue = this.values[val.fieldname];
			}
			if(val.conditionlogic){
				this.conditions[val.fieldname] = ((val.conditionlogic.logic!='show')?true:false);
			}
		});
		this.updateConditions();
		this.review = true;
	}
	/**
	 * actualiza el estado de las codiciones logicas
	 * calcula si una condicion logica esta o no cumplida 
	 * activa cambios en el formulario principal
	 */
	updateConditions(){
		let formFields: FieldConfig[] = this.fields.filter(e=> { return e.conditionlogic });
		formFields.forEach((field, key)=>{
			this.conditions[field.fieldname] = (field.conditionlogic.logic!='show')?true:false;
			let cond: boolean;
			field.conditionlogic.conditions.forEach((c, key)=>{
				if(c.operator == 'birthdate'){
					if(this.getDefaultValue(c.fieldname) && this.getDefaultValue(c.fieldname)!=''){
						let dateYear;
						if(typeof this.getDefaultValue(c.fieldname) == 'object'){
							dateYear = new Date().getFullYear() - parseInt(this.getDefaultValue(c.fieldname).format('YYYY'));
						}else{
							let date = new FormControl(new Date(this.getDefaultValue(c.fieldname).replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")));
							dateYear = new Date().getFullYear() - parseInt(moment(date.value).format('YYYY'));
						}
						cond = (dateYear<18);
					}else{
						cond = false;
					}
				}else{
					let isS: boolean = this.isSelect(c.fieldname);
					let isM: boolean = this.isMSelect(c.fieldname);
					let isC: boolean = this.isCheckbox(c.fieldname);
					let fValue = this.getDefaultValue(c.fieldname);
					switch (field.conditionlogic.method) {
						case "and":
							if(c.operator == '=='){
								if(key==0){
									if(!isS && !isM && !isC){
										cond = (fValue)?fValue == c.value:false;
									}
									else{
										if(isS)
											cond = (fValue)?fValue.value == c.value:false;
										if(isM)
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										if(isC)
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
									}
								}else{
									if(!isS && !isM && !isC){
										cond = cond && fValue == c.value;
									}
									else{
										if(isS)
											cond = cond && (fValue)?fValue.value == c.value:false;
										if(isM)
											cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										if(isC){
											cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										}
									}
								}								
							}
							else{
								if(key==0){
									if(!isS && !isM && !isC){
										cond = fValue != c.value;
									}
									else{
										if(isS)
											cond = (fValue)?fValue.value != c.value:false;
										if(isM)
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										if(isC){
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										}
									}
								}else{
									if(!isS && !isM && !isC){
										cond = cond && (fValue)?fValue != c.value:false;
									}
									else{
										if(isS)
											cond = cond && (fValue)?fValue.value != c.value:false;
										if(isM)
											cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										if(isC){
											cond = cond && (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										}
									}
								}									
							}	
							break;
						case "or":
							if(c.operator == '=='){
								if(key==0){
									if(!isS && !isM && !isC){
										cond = (fValue)?fValue == c.value:false;
									}
									else{
										if(isS)
											cond = (fValue)?fValue.value == c.value:false;
										if(isM)
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										if(isC){
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										}
									}
								}else{
									if(!isS && !isM && !isC){
										cond = cond || (fValue)?fValue == c.value:false;
									}
									else{
										if(isS)
											cond = cond || (fValue)?fValue.value == c.value:false;
										if(isM)
											cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										if(isC){
											cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length >0:false;
										}
									}
								}								
							}
							else{
								if(key==0){
									if(!isS && !isM && !isC){
										cond = (fValue)?fValue != c.value:false;
									}
									else{
										if(isS)
											cond = (fValue)?fValue.value != c.value:false;
										if(isM)
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										if(isC){
											cond = (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										}
									}
								}else{
									if(!isS && !isM && !isC){
										cond = cond || (fValue)?fValue != c.value:false;
									}
									else{
										if(isS)
											cond = cond || (fValue)?fValue.value != c.value:false;
										if(isM)
											cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										if(isC){
											cond = cond || (fValue)?fValue.filter(e=>{return e.value=== c.value}).length <= 0:false;
										}
									}
								}								
							}	
							break;
						default:
							break;
					}
				}
			});
			if(field.conditionlogic.logic == 'hidden'){
				cond = !cond;
			}
			this.conditions[field.fieldname] = cond;
		});
		this.complete.emit(this.getFinally());
	}
	/**
	 * determina si un elemento es un select simple
	 * @param  {string}  fieldname nombre logico del elemento
	 * @return {boolean}           valor de verdad retornado
	 */
	isSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && e.subtype==='select' && !e.search });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * determina si un elemento es un select multiple
	 * @param  {string}  fieldname nombre logico del elemento
	 * @return {boolean}           valor de verdad retornado
	 */
	isMSelect(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && (e.search || e.subtype==='multiselect') });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * determina si un elemento es un checkbox
	 * @param  {string}  fieldname nombre logico del elemento
	 * @return {boolean}           valor de verdad retornado
	 */
	isCheckbox(fieldname: string): boolean{
		let r = false;
		let t:FieldConfig[] = this.fields.filter(e=>{ return e.fieldname===fieldname && e.type==='checkbox' });
		if(t.length>0){
			r = true;
		}
		return r;
	}
	/**
	 * obtiene el valor por defececto de un elemento
	 * @param  {string} name nombre del elemento
	 * @return {any}         valor
	 */
	getDefaultValue(name: string): any{
		let val: any=null;
		let f: FieldConfig = this.fields.filter(e=>{ return e.fieldname === name })[0];
		if(f.subtype=='select' && !f.search){
			val = f.defaultvalue[0];
		}else{
			val = f.defaultvalue;	
		}
		return val;
	}
	/**
	 * formateo final de datos 
	 * para render
	 */
	getFinally(){
		let final: any = {};

		this.fields.forEach(f=>{
			let show: boolean = true;
			if(this.conditions[f.fieldname] == false){
				show = this.conditions[f.fieldname];
			}
			f['show'] = show;
			let element: any = {
				value: f.defaultvalue,
				show: show
			};
			final[f.fieldname] = element;
		});
		return final;
	}
	/**
	 * emite evento de regreso a pagina
	 */
	toDetail(){
		this.back.emit();
	}
}
