import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MatTableDataSource } from '@angular/material';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';

export const DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		dateOutput: 'DD/MM/YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
};

@Component({
	selector: 'kt-schedule',
	templateUrl: './schedule.component.html',
	styleUrls: ['./schedule.component.scss'],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: 'es-BO'},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
	],
})

export class ScheduleComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	preview: boolean = false;
	/*---variables schedule---*/
	formSchedule: FormGroup;
	displayCols: string [] = ['num', 'activity', 'start', 'end', 'options'];
	data: any[]=[];
	item: any[] = [];
	buttonModal:string = 'Agregar';
	modal: NgbModalRef;
	indexEdit:number;
	isProccessing: boolean = false;
	minDate;
	maxDate;
	maxRows: number;
	minS;
	maxE;
	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder, 
	){}
	/**
	 * inicializar pagina
	 */
	ngOnInit() {
		this.initColumns();
		this.maxRows = this.getMax();
		this.initForm();
	}
	/**
	 * inicializa formulario
	 * de componente
	 */
	initForm(){
		if(this.field.defaultvalue){
			this.item = this.field.defaultvalue;
			this.data = [...this.item];
		}
		this.formSchedule = this.fb.group(
			{
				actividad:['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
				inicio:['', Validators.required],
				fin:['', Validators.required]
			}
		);
		this.initMinMax();
	}
	/**
	 * abre modal para agregar elementos
	 * en la tabla
	 * @param {modal} content
	 */
	openLarge(content) {
		this.minDate = undefined;
		this.maxDate = undefined;
		if(this.field.startdate){
			this.setMaxMin(this.field.startdate);
		}
		if(this.field.enddate){
			this.setMinMax(this.field.enddate);
		}
		this.buttonModal = 'Agregar';
		this.formSchedule.reset();
		this.isProccessing = false;
        if(this.maxRows == 0 || (this.maxRows != 0 && this.item.length < this.maxRows)){
			this.modal = this.modalService.open(content, {
	            size: 'lg',
	            backdrop : 'static',
	            windowClass:'table_predesign',
	      		keyboard : false
	        });
		}
    }
    /**
     * agrega o edita elemento en la tabla
     * @param {button} event boton que dispara la funcion aeI()
     */
    aeI(event){
    	event.preventDefault();
		event.stopPropagation();
		if(!this.preview){
			if(this.formSchedule.valid){
				this.isProccessing = true;
				if(this.buttonModal=='Agregar'){
					this.item.push(this.prepare());
				}else{
					this.item[this.indexEdit] = this.prepare();
				}
				this.data = [...this.item];
				this.group.get(this.field.fieldname).setValue(this.item);
				this.modal.close();
				this.isProccessing = false;
			}else{
				this.validateAllFormFields(this.formSchedule);
			}
		}
    }
    /**
     * formateo de datos a ser guardados en la tabla
     * @return {cronograma} elemento de la tabla
     */
    prepare(): cronograma{
    	let act: cronograma ={
    		actividad: this.formSchedule.value['actividad'],
    		inicio: this.formSchedule.value['inicio'].format('DD/MM/YYYY'),
    		fin: this.formSchedule.value['fin'].format('DD/MM/YYYY')
    	}
    	return act;
    }
    /**
     * editar elemento de la tabla
     * @param {number}     index   index del elemento en la tabla
     * @param {cronograma} element datos del elemento a editar
     * @param {modal}     mod     modal para editar datos
     */
    edit(index, element: cronograma, mod){
		this.buttonModal = 'Editar';
		this.indexEdit = index;
		let id = new Date(element['inicio'].replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
		let fd = new Date(element['fin'].replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
		let i = new Date(element['inicio'].replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
		let f = new Date(element['fin'].replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
		i.setDate(i.getDate());
		f.setDate(f.getDate());
		this.formSchedule.patchValue({actividad:element['actividad'], inicio:moment(id), fin:moment(fd)});
		this.formSchedule.updateValueAndValidity();
		this.minDate = i;
		this.maxDate = f;
		this.modal = this.modalService.open(mod, {
            size: 'lg',
            backdrop : 'static',
            windowClass:'table_predesign',
      		keyboard : false
        });
        this.isProccessing = false;
	}
	/**
	 * eliminar elmento de la tabla
	 * 
	 * @param {number} index   index del elemento en la tabla
	 * @param {any} element datos a ser elminados
	 */
	delete(index, element){
		this.item.splice(index,1);
		if(this.item.length==0){
			this.group.get(this.field.fieldname).reset();	
		}else{
			this.group.get(this.field.fieldname).setValue(this.item);
		}
		this.data = [...this.item];
	}
	/**
	 * validar todos los elementos del formulario
	 * @param {FormGroup} formGroup formulario
	 */
    validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
	/**
	 * actuliza max, min para los elementos en el formulario
	 * @param {string}                        type  tipo de actualizacion [min, max]
	 * @param {MatDatepickerInputEvent<Date>} event datos de fecha seleccionada
	 */
	addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
		let val = new Date(event.value);
	    if(type == 'min'){
	    	val.setDate(val.getDate());
	    	this.minDate = val;
	    }
	    else{
	    	val.setDate(val.getDate());	    	
	    	this.maxDate = val;
	    }
	}
	/**
	 * obtiene el maximo de registros para la tabla
	 * @return {number} limite de registros
	 */
	getMax(): number{
		let max: number = 0;
		if(this.field.validations){
			let val = this.field.validations.filter(e=>{ return e.name==='maxrows';});
			if(val.length == 1){
				max = val[0].value;
			}
		}
		return max;
	}
	/**
	 * inicializa las columnas que se mostraran en la tabla
	 */
	initColumns(){
		if(this.field.config){
			if((this.field.config.edit || this.field.config.delete) && !this.review){
				this.displayCols = ['num', 'activity', 'start', 'end', 'options'];
			}
			else{
				this.displayCols = ['num', 'activity', 'start', 'end'];	
			}
		}
	}
	/**
	 * inicializacion por defecto de maximos y minimos en las fechas
	 */
	initMinMax(){
		if(this.field.startdate){
			this.setMin(this.field.startdate);
		}
		if(this.field.enddate){
			this.setMax(this.field.enddate);			
		}
	}
	/**
	 * agrega fecha minina de seleccion
	 * @param {string} min tipo de fecha de inicio
	 */
	setMin(min: string){
		let date;
		switch (min) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(min.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.minS = date;
	}
	/**
	 * agrega fecha maxima de seleccion
	 * @param {string} max tipo de fecha maxima
	 */
	setMax(max:string){
		let date;
		switch (max) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(max.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.maxE = date;
	}
	/**
	 * agrega fecha maxima de seleccion para la fecha inicio
	 * @param {string} max tipo de fecha
	 */
	setMinMax(max: string){
		let date;
		switch (max) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(max.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}
		this.maxDate = date;
	}
	/**
	 * agrega fecha minima de seleccion para la fecha final
	 * @param {string} min tipo de fecha
	 */
	setMaxMin(min: string){
		let date;
		switch (min) {
			case "hoy":
				date = new Date();	
				break;
			case "mañana":
				date = new Date();	
				date.setDate(date.getDate()+1);
				break;
			default:
			date = new Date(min.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));	
			break;
		}		
		this.minDate = date;
	}
}
/**
 * interface cronograma
 * modelo de datos almacenados en la tabla
 */
export interface cronograma{
	actividad: string;
	inicio: any;
	fin: any;
}