// Angular
import { Injectable } from '@angular/core';
import { HttpClient,HttpSentEvent, HttpErrorResponse, HttpHeaders, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
// RxJS
import { Observable, throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { share, tap, filter, take, switchMap, catchError, map, withLatestFrom, exhaustMap, finalize } from 'rxjs/operators';
import { debug } from 'util';
import { Store, select, ActionsSubject  } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { AppState } from '../../../reducers';
import { currentAuth, Logout, RefreshToken, Login, AuthActionTypes } from '../../../auth/';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class InterceptService implements HttpInterceptor {
 	authToken: string;
 	refreshToken:string;
 	isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
 	constructor(private http: HttpClient,private store: Store<any>) {
 		let t: Observable<any> = this.store.pipe(select(currentAuth));
 		t.subscribe(
 			res=>{
 				this.authToken = res.Token;
 				this.refreshToken = res.refreshToken;
 			}
 		);
 	}
 	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
            return next.handle(this.addToken(req, this.authToken)).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && (!req.url.includes("login")&&!req.url.includes("register")&&!req.url.includes("reset")&&!req.url.includes("forgot")&&!req.url.includes("facebook"))) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 401:
                            return this.handle401Error(req, next);
                            break;
                        case 400:
                            if(req.url.includes("refresh")){
                                return this.handle400Error(error);
                            }
                            else{
                                return observableThrowError(error);    
                            }
                            break;
                        default:
                            return observableThrowError(error);
                            break;
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

 	addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        if (!token)
            return req;
        return req.clone({setHeaders: {Accept: 'application/json',Authorization: 'Bearer '+token}});
    }
    handle400Error(error){
        if(error && error.status === 400 && error.error){
            return this.logoutUser();
        }
        return Observable.throw(error);
    }
    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            return this.refresh(this.refreshToken).pipe(
                switchMap((newToken: string) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        return next.handle(this.addToken(req, newToken));
                    }
                }),
                catchError((e) => {
                	this.tokenSubject.next(null);
                    //console.log('if1');
                    return observableThrowError(e);
                }),
                finalize(() => {
                	this.isRefreshingToken = false;
                }));
        } else {
        	return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }),
                catchError((e) => {
                    this.isRefreshingToken=false;
                    return observableThrowError(e);
                }),
                );
	        
        }
    }
    logoutUser() {
        //console.log('Logout');
        this.isRefreshingToken=false;
        this.tokenSubject.next(null);
        this.store.dispatch(new Logout());
        return observableThrowError("resfreshError");
    }

    refresh(refresh_token: string): Observable<string>{
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Accept','application/json');
        httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(`${environment.apiUrl}/web/refresh`, { refresh_token }, { headers: httpHeaders })
        .pipe(
            share(),
            map((data:any) => {
                let t: string = data.access_token;
                let r: string = data.refresh_token;
                let e: number = data.expires_in;
                this.store.dispatch(new RefreshToken({authToken:t, refreshToken:r, expire:e}));
                return <string>data.access_token;
            })
        );
    }
 }
