import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MatTableDataSource } from '@angular/material';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, from  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';

@Component({
	selector: 'kt-budget',
	templateUrl: './budget.component.html',
	styleUrls: ['./budget.component.scss']
})

export class BudgetComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	preview: boolean = false;
	/*---variables budget---*/
	formBudget: FormGroup;
	displayCols: string [] = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total', 'options'];
	data: any[]=[];
	item: any[] = [];
	options: options[] = [];
	buttonModal:string = 'Agregar';
	modal: NgbModalRef;
	indexEdit:number;
	isProccessing: boolean = false;
	totalOTF: number = 0;
	totalPIU: number = 0;
	total: number = 0;
	aux = new BehaviorSubject<any>(null);
	maxRows: number;
	ready: boolean = false;

	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder, 
		private http: HttpClient, 
		private toastr: ToastrService
	){}
	/**
	 * inicializa la configuracion del componente
	 */
	ngOnInit() {
		this.initColumns();
		this.maxRows = this.getMax();
		this.initForm();
	}
	/**
	 * inicializa el formulario del componente
	 */
	initForm(){
		if(this.field.defaultvalue){
			this.item = this.field.defaultvalue;
			this.item.sort((a,b)=>{return b.tipo.localeCompare(a.tipo);});
			this.data = [...this.item];
			this.upTotal();
		}
		if(!this.review){
			this.initOptions(this.field.optionstype);
			this.formBudget = this.fb.group(
				{
					tipo:['', Validators.required],
					itemOTF:['', Validators.compose([Validators.required, Validators.maxLength(100)])],
					itemPIU:['', Validators.required],
					detalle:['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
					cantidad:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					costoU:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					costoT:['', Validators.required]
				}
			);
			this.changeItem();
		}
	}
	/**
	 * abre modal para agregar elmentos nuevos en la tabla
	 * @param {modal} content modal para crear o editar elementos
	 */
	openLarge(content) {
		this.buttonModal = 'Agregar';
		if(this.maxRows == 0 || (this.maxRows != 0 && this.item.length < this.maxRows)){
			this.formBudget.reset({
				tipo:'',
				itemOTF:'',
				itemPIU:'',
				detalle:'',
				cantidad:'',
				costoU:'',
				costoT:''
			});
	        this.modal = this.modalService.open(content, {
	            size: 'lg',
	            backdrop : 'static',
	            windowClass:'table_predesign',
	      		keyboard : false
	        });
	    }
		this.isProccessing = false;
    }
    /**
     * agregar o editar elemento
     * @param {button} event
     */
    aeI(event){
    	event.preventDefault();
		event.stopPropagation();
		if(!this.preview){
			if(this.formBudget.valid){
				this.isProccessing = true;
				if(this.buttonModal=='Agregar'){
					this.item.push(this.formBudget.value);
				}else{
					this.item[this.indexEdit] = this.formBudget.value;
				}
				this.item.sort((a,b)=>{return b.tipo.localeCompare(a.tipo);});
				this.data = [...this.item];
				this.group.get(this.field.fieldname).setValue(this.item);
				this.upTotal();
				this.modal.close();
				this.isProccessing = false;
			}else{
				this.validateAllFormFields(this.formBudget);
			}
		}
    }
    /**
     * carga datos a ser editados en 
     * modal principal
     * 
     * @param {number} index   index del elemento a ser editado
     * @param {any} element datos del elemento
     * @param {modal} mod     modal principal
     */
    edit(index, element, mod){
		this.buttonModal = 'Editar';
		this.indexEdit = index;
		this.formBudget.patchValue(element);
		this.formBudget.updateValueAndValidity();
		this.changeType(element.tipo);
		this.modal = this.modalService.open(mod, {
            size: 'lg',
            backdrop : 'static',
            windowClass:'table_predesign',
      		keyboard : false
        });
        this.isProccessing = false;
	}
	/**
	 * eliminar elemento de la tabla
	 * @param {number} index   index del elemento en la tabla
	 * @param {any} element datos del elemento a ser eliminado
	 */
	delete(index, element){
		this.item.splice(index,1);
		if(this.item.length==0){
			this.group.get(this.field.fieldname).reset();	
		}else{
			this.group.get(this.field.fieldname).setValue(this.item);
		}
		this.upTotal();
		this.data = [...this.item];
	}
	/**
	 * inicializar opciones del formulario principal
	 * @param {string} type tipo de fuente de las opciones
	 */
	initOptions(type: string){
		switch (type) {
			case "custom":
				this.options = this.field.options;
				this.ready = true;
				break;
			default:
				this.getOptions(type);
				break;
		}
	}
	/**
	 * peticion de opciones para el formulario
	 * @param {string} type tipo de catalogo requerido
	 */
	getOptions(type: string){
		let apiUrl = `${environment.apiUrl}/a/catalog_items/code/${this.field.options}?include=catalog_items`;
		let resp:Observable<any> = this.http.get(apiUrl);
		resp.subscribe(
			response=>{
				let opt: any[] = response.data.catalog_items.data;
				this.options = [];
				opt.forEach(c=>{
					this.options.push({text: c.name, value: c.id});
				});
				this.ready = true;
				this.aux.next(`load options of ${this.field.fieldname}`);
			},
      		error=>{
      		},
		);
	}
	/**
	 * manejo de errores
	 * @param {error} e error producido por alguna peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * valida todos los elementos del formulario
	 * @param {FormGroup} formGroup formulario
	 */
    validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
	/**
	 * monitor de cambios en el formulario
	 * @param {string} value tipo de cambio
	 */
    changeType(value: string):void{
    	switch (value) {
    		case "Programa de Intervenciones Urbanas(PIU)":
    			this.formBudget.get('itemOTF').setValidators([Validators.maxLength(100)]);
    			this.formBudget.get('itemPIU').setValidators([Validators.required]);
    			break;
    		case "Otras Fuentes de Financiamiento":
    			this.formBudget.get('itemOTF').setValidators([Validators.required, Validators.maxLength(100)]);
    			this.formBudget.get('itemPIU').setValidators([]);
    			break;    		
    		default:
    			break;
    	}
    	if(this.buttonModal == 'Agregar'){
    		this.formBudget.patchValue({
				itemOTF:'',
				itemPIU:'',
				detalle:'',
				cantidad:'',
				costoU:'',
				costoT:''
			});
    	}    	
    	this.formBudget.updateValueAndValidity();
    }
    /**
     * monitor de cambios en montos del formulario
     */
    changeItem(){
    	this.formBudget.get('cantidad').valueChanges.subscribe(v=>{
    		this.upTotalItem();
    	});
    	this.formBudget.get('costoU').valueChanges.subscribe(v=>{
    		this.upTotalItem();
    	});
    }
    /**
     * calculo de costo total del item en el formulario
     */
    upTotalItem(){
    	if(this.formBudget.get('cantidad').valid && this.formBudget.get('costoU').valid){
    		this.formBudget.get('costoT').setValue(this.formBudget.controls.cantidad.value*this.formBudget.controls.costoU.value);
    		this.formBudget.get('costoT').updateValueAndValidity();
    	}
    }
    /**
     * calculo de montos totales en el componente
     */
    upTotal(){
    	this.totalOTF = 0;
    	this.totalPIU = 0;
    	this.total = 0;
    	this.item.forEach(v=>{
    		this.total += parseInt(v.costoT);
	    	switch (v.tipo) {
	    		case "Programa de Intervenciones Urbanas(PIU)":
	    			this.totalPIU += parseInt(v.costoT);
	    			break;
	    		case "Otras Fuentes de Financiamiento":
	    			this.totalOTF += parseInt(v.costoT);
	    			break;    		
	    		default:
	    			break;
	    	};
    	});    	
    }
    /**
     * formateo de numero para ser presentado
     * @param  {numero} value valor
     * @return {string}       numero en formato para ser presentado
     */
    toFormatNum(value): string{
    	let r = Number(value).toLocaleString('en-GB');
    	return r;
    }
    /**
     * obtiene el limite maximo de registros para la tabla
     * @return {number} limite de registros
     */
    getMax(): number{
		let max: number = 0;
		if(this.field.validations){
			let val = this.field.validations.filter(e=>{ return e.name==='maxrows';});
			if(val.length == 1){
				max = val[0].value;
			}
		}
		return max;
	}
	/**
	 * inicializa columnas que seran mostradas en el componente
	 */
	initColumns(){
		if(this.field.config){
			if((this.field.config.edit || this.field.config.delete) && !this.review){
				this.displayCols = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total', 'options'];
			}
			else{
				this.displayCols = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total'];
			}
		}
	}
}
/**
 * interface de opciones
 * modelo de datos de las opciones en el formulario
 */
export interface options{
	text?: string;
	value?: string;
	selected?: boolean;
}