import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";

@Component({
	selector: 'kt-textbox',
	templateUrl: './textbox.component.html',
	styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements OnInit, AfterContentChecked {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	type: string;

	constructor(private cdref: ChangeDetectorRef) { }
	/**
	 * incializar el tipo de elemento
	 */
	ngOnInit() {
		switch (this.field.subtype) {
			case "digits":
			case "decimal":
				this.type = 'number'
				break;
			case "static":
			case "phone":
				this.type = 'text';
				break;
			default:
				this.type = this.field.subtype
				break;
		};
	}
	/**
	 * detectar cambios
	 */
	ngAfterContentChecked(): void {
        this.cdref.detectChanges();
    }
    /**
     * formato de numero
     *
     * @param      {<number>}  value  valor
     * @return     {string} { numero en formato para mostrar }
     */
    toFormatNum(value): string{
    	let r = Number(value).toLocaleString('en-GB');
    	return r;
    }
    /**
     * abrir urls
     *
     * @param      {string}  url     url
     */
    goToUrl(url: string){
    	if(url.indexOf('http:')>-1 || url.indexOf('https:')>-1 ){
	    	window.open(url, '_blank');
    	}
    }
}
