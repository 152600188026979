import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kt-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
/**
 * componente que muestra el loading en cada pagina
 *
 * @class      LoadingComponent 
 */
export class LoadingComponent implements OnInit {
	@Input() type:string = '';

	constructor() { }

	ngOnInit() {
	}

}
