// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, UserLoaded, Logout } from '../../../../core/auth';
//ng-login
import { AuthService as sg } from '../../../../core/ng-login/auth.service';
import { FacebookLoginProvider } from '../../../../core/ng-login/providers';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	loadingFacebook = new BehaviorSubject<boolean>(false);
	enableFacebook: boolean = false;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private authService: sg
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.enableFacebook = environment.enableFacebook;
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
			])
			],
			password: ['', Validators.compose([
				Validators.required,
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const authData = {
			email: controls['email'].value,
			password: controls['password'].value
		};
		this.auth
			.login(authData.email, authData.password)
			.pipe(
				tap(user => {
					if (user && user.roles.filter(r=>{ return r.name==='postulant'; }).length > 0) {
						this.store.dispatch(new Login({authToken: user.accessToken, refreshToken: user.refreshToken, expire:user.expire, confirmed: user.confirmed}));
						this.authNoticeService.setNotice(this.translate.instant(`Bienvenido ${user.username}`), 'success');
						this.router.navigateByUrl(this.returnUrl); // Main page
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
				}),
				catchError((err) => {
			        this.catchError(err);
			        return throwError(err);
			    }),			
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	/*---login facebook---*/
	loginFacebook() {
	    this.loadingFacebook.next(true);
	    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
	    	this.auth
			.loginFacebook(res.authToken, `${location.origin}/verificar/correo`)
			.pipe(
				tap(user => {
					if (user && user.roles.filter(r=>{ return r.name==='postulant'; }).length > 0) {
						this.store.dispatch(new Login({authToken: user.accessToken, refreshToken: user.refreshToken, expire:user.expire, confirmed: user.confirmed}));
						this.router.navigateByUrl(this.returnUrl); // Main page
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
				}),
				catchError((err) => {
			        this.catchError(err);
			        return throwError(err);
			    }),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	    }).catch(err => {this.loadingFacebook.next(false);});
	}
	catchError(e){
		if(typeof e == 'object'){
			this.authNoticeService.setNotice(e.error.message, 'danger');
		}
		else{			
			this.authNoticeService.setNotice(e.toString(), 'danger');
		}
	}
}
