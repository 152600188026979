import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, empty } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';

const API_USERS_URL = '/web/login';
const API_USERS_LOGIN = '/web/login';
const API_USERS_LOGIN_FACEBOOK = '/auth/facebook';
const API_USERS_TOKEN = '/user/profile';
const API_USER_ID_URL = '/persons/user/';
const API_ROLES_URL = 'api/roles';
const API_PERMISSION_URL = 'api/roles';
const Client_ID = 3;
const Client_Secret = '7eS0DUoC2qZt8vFNhduEqTfYyZyhBYADx3iHSFrI';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient, private store: Store<AppState>) {}
    
    login(email: string, password: string):Observable<User> {
        return this.http.post(environment.apiUrl+API_USERS_LOGIN, { email, password })
        .pipe<User>(
            map((data:any) => {
                let user= {
                    id: data.data.id,
                    username: data.data.name,
                    email: data.data.email,
                    confirmed: data.data.confirmed,
                    accessToken: data.meta.custom.authorization.access_token,
                    refreshToken: data.meta.custom.authorization.refresh_token,
                    expire: data.meta.custom.authorization.expires_in,
                    roles: data.data.roles.data,
                };
                return <User>user;
            })
        );
    }
    loginFacebook(oauth_token: string, verifyurl: string):Observable<User> {
        return this.http.post(environment.apiUrl+API_USERS_LOGIN_FACEBOOK, { oauth_token, verifyurl })
        .pipe<User>(
            map((data:any) => {
                let user= {
                        id: data.data.id,
                        username: data.data.name,
                        email: data.data.email,
                        confirmed: data.data.confirmed,
                        accessToken: data.meta.custom.authorization.access_token,
                        refreshToken: data.meta.custom.authorization.refresh_token,
                        expire: data.meta.custom.authorization.expires_in,
                        roles: data.data.roles.data,
                    };
                return <User>user;
            })
        );
    }
    logout():Observable<any>{
        return this.http.delete(`${environment.apiUrl}/logout`);
    }
    getUserByToken(): Observable<any> {
        return this.http.get<User>(`${environment.apiUrl}${API_USERS_TOKEN}`)
        .pipe(
            map((data:any) => {
                let user= {
                    id: data.data.id,
                    username: data.data.name,
                    email: data.data.email,
                    confirmed: data.data.confirmed,
                };
                return <User>user;
            }),
            catchError(err => {
                return null;
            })
        );
    }
    register(user: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Accept','application/json');
        httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<User>(`${environment.apiUrl}/register`, user, { headers: httpHeaders })
            .pipe(
                map((data: any) => {
                    let user= {
                        id: data.data.id,
                        username: data.data.name,
                        email: data.data.email,
                        confirmed: data.data.confirmed,
                        accessToken: data.meta.custom.authorization.access_token,
                        refreshToken: data.meta.custom.authorization.refresh_token,
                        expire: data.meta.custom.authorization.expires_in,
                        roles: data.data.roles.data,
                    };
                return <User>user;
                }),
                catchError(err => {
                    return empty();
                })
            );
    }
    resetPassword(user: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Accept','application/json');
        httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(`${environment.apiUrl}/password/reset`, user, { headers: httpHeaders })
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }
    public requestPassword(email: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Accept','application/json');
        httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(`${environment.apiUrl}/password/forgot`,email, {headers: httpHeaders})
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }
    
    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */

    getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: string): Observable<User> {
		return this.http.get<User>(`${environment.apiUrl}${API_USER_ID_URL}${userId}`)
        .pipe<User>(
            map((data:any) => {
                let user= {
                        id: data.data.id,
                        username: data.data.name,
                        email: data.data.email,
                    };
                return <User>user;

            })
        );
	}


    // DELETE => delete the user from the server
	deleteUser(userId: string) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
    	const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders});
	}

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: any): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<any>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders});
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
	}

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: any): Observable<any> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<any>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
	}

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
