import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit  } from '@angular/core';
import { RouterOutlet, Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSelectionList  } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../environments/environment';
import { DropzoneComponent , DropzoneDirective,  DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
/*---NGRX---*/
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { currentUserId, currentAuthToken } from '../../../../../core/auth';

export const DATE_FORMAT = {
	parse: {
		dateInput: 'DD/MM/YYYY',
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD/MM/YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
};

@Component({
	selector: 'kt-form-perfil',
	templateUrl: './form-perfil.component.html',
	styleUrls: ['./form-perfil.component.scss'],
	providers: [
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
	],
})

export class FormPerfilComponent implements OnInit {
	@Input() type: string;
	@Input() status: string;
	@Output() cancel: EventEmitter<any> = new EventEmitter();
	@Output() isReady: EventEmitter<any> = new EventEmitter();
	aux = new BehaviorSubject<any>(null);
	title:string;
	public natural: FormGroup;
	public naturalData:person_natural;
	public legal: FormGroup;
	public legalData: person_legal;
	public colective: FormGroup;
	public colectiveData: person_colective;
	public tab:number = 0;
	public submitted = false;
	public depto:any[] = [];
	public tso:any[] = [];
	public pj:string = 'Sociedad Comercial';
	public idP:string;
	public id:string;
	public config: any;
	public returnUrl:string;
	@ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;

	constructor(
		private store: Store<AppState>, 
		private fb: FormBuilder, 
		private http: HttpClient, 
		private outlet: RouterOutlet, 
		private router: Router, 
		private toastr: ToastrService
	) { }
	/**
	 * inicializa la pagina
	 */
	ngOnInit() {
		if(history.state.url){
			this.returnUrl = history.state.url;
		}
		let t: Observable<any> = this.store.pipe(select(currentUserId));
		t.subscribe(
			res=>{
				if(res!=null && res!=this.id){
					this.id = res;
					this.iniForms(this.type);
				}
			}
		);
		let cf: Observable<any> = this.store.pipe(select(currentAuthToken));
		cf.subscribe(
			res=>{
				if(res!=null){
					this.config = {
						headers:{
							'Authorization': 'Bearer '+res,
							'Cache-Control': null,
				        	'X-Requested-With': null
			        	}
					};
				}
			}
		);		
	}
	/**
	 * Initializa los formularios
	 *
	 * @param      {string}  type    tipo de perfil
	 * @param      {string}  idP     id de perfil
	 */
	iniForms(type: string, idP:string = ''){
		if(this.status=='0'){
			this.getDepto();
		}
		let urlApi = environment.apiUrl+'/persons/user/'+this.id;
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Accept':'application/json',
		    	
		  	}),
		  	observe: 'response' as 'body'
		};
		let resp:Observable<any> = this.http.get(urlApi,httpOptions);
		resp.subscribe(
			data=>{
				let r = data.body.data;
				for(let d of r.perfil_persons.data){
					if(d.type == type.toUpperCase() && d.active ==1)
						idP = d.id;
				}
				if((this.status=='1' && idP=='')||(this.status=='0' && idP!='')){
					this.router.navigate(['/perfil/seleccionar']);
				}
				switch (type) {
					case "pn":
						this.title = 'Persona Natural';
						this.formPersonNatural(this.status, idP);
						break;
					case "pj":
						this.title = 'Persona Juridica';
						if(this.status=='0'){
							this.getTSO();
						}
						this.formPersonLegal(this.status, idP);
						break;
					case "ac":
						this.title = 'Colectivo / Organización Civil';
						this.formPersonColective(this.status, idP);
						break;
					default:
						this.title = '';
						break;
				}		
				this.aux.next('getPerfilData');
			},
      		error=>{
      			this.catchError(error);
      		},
		);		
	}
	
	/**
	 * formulario de persona natural
	 *
	 * @param      {string}  status  estado crear/editar
	 * @param      {string}  idP     id de perfil
	 */
	formPersonNatural(status, idP=''){
		if(status=='0'){
			this.natural = this.fb.group(
				{
					type:[this.type.toUpperCase()],
					user_id:[this.id],
					first_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					last_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					birthday:['', Validators.required],
					document_type:['', Validators.required],
					document:['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(12)])],
					exp:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					nationality:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
					department_id:['', Validators.required],
				},{
					validator:this.phone_cell
				}
			);
		}
		else{
			this.idP = idP;
			this.natural = this.fb.group(
				{
					type:[this.type.toUpperCase()],
					user_id:[this.id],
					telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
				},{
					validator:this.phone_cell
				}
			);
			let urlApi = environment.apiUrl+'/persons/'+idP;
			const httpOptions = {
			  	headers: new HttpHeaders({
			    	'Accept':'application/json',
			    	
			  	}),
			  	observe: 'response' as 'body'
			};
			let resp:Observable<any> = this.http.get(urlApi,httpOptions);
			resp.subscribe(
				response=>{
					let dt = response.body.data.perfil_data.data;
					this.naturalData = {
						first_name:dt.first_name,
						last_name:dt.last_name,
						birthday:dt.birthday,
						document_type:dt.document_type,
						document:dt.document,
						exp:dt.exp,
						nationality:dt.nationality,
						telephone:dt.telephone,
						cellphone:dt.cellphone,
						email:dt.email,
						department_id:dt.residency_department
					};
					this.natural.patchValue({
						telephone:(this.naturalData.telephone!=null)?this.naturalData.telephone:'',
						cellphone:(this.naturalData.cellphone!=null)?this.naturalData.cellphone:'',
						email:this.naturalData.email,
					});
					this.isReady.emit('ready');
					this.aux.next('get natural');
				},
	      		error=>{
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * formulario de persona juridica
	 *
	 * @param      {string}  status  estado crear/editar
	 * @param      {string}  idP     id de perfil
	 */
	formPersonLegal(status, idP=''){
		if(status=='0'){
			this.legal = this.fb.group(
				{
					type:[this.type.toUpperCase()],
					user_id:[this.id],
					rep_first_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					rep_last_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					rep_document_type:['', Validators.required],
					rep_document:['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(12)])],
					rep_exp:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					rep_nationality:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					rep_telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					rep_cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					rep_email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
					business_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					code_fundempresa:['', Validators.compose([Validators.required, Validators.maxLength(100)])],
					number_testimony:['', Validators.compose([Validators.maxLength(100), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')])],
					number_resolution:['', Validators.compose([Validators.maxLength(100), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')])],
					type_business_id:['', Validators.required],
					nit:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					number_legal_rep:['', Validators.compose([Validators.required, Validators.maxLength(20), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')])],
					website:['', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
					social:['', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
					telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
					operation_department_id:['', Validators.required],
					type_society:[this.pj, Validators.required],
				},{
					validator:[this.phone_cell, this.rep_phone_cell],
				}
			);
		}
		else{
			this.idP = idP;
			let urlApi = environment.apiUrl+'/persons/'+idP;
			const httpOptions = {
			  	observe: 'response' as 'body'
			};
			let resp:Observable<any> = this.http.get(urlApi,httpOptions);
			resp.subscribe(
				response=>{
					let dt = response.body.data.perfil_data.data;
					let tbN = response.body.data.perfil_data.data.type_business.data.name;
					this.legalData = {
						rep_first_name:dt.rep_first_name,
						rep_last_name:dt.rep_last_name,
						rep_document_type:dt.rep_document_type,
						rep_document:dt.rep_document,
						rep_exp:dt.rep_exp,
						rep_nationality:dt.rep_nationality,
						rep_telephone:(dt.rep_telephone!=null)?dt.rep_telephone:'-',
						rep_cellphone:(dt.rep_cellphone!=null)?dt.rep_cellphone:'-',
						rep_email:dt.rep_email,
						business_name:dt.business_name,
						code_fundempresa:dt.code_fundempresa,
						number_testimony:dt.number_testimony,
						number_resolution:dt.number_resolution,
						type_business_id:(response.body.data.perfil_data.data.type_business=!undefined)?tbN:'',
						nit:(dt.nit!=null)?dt.nit:'No Corresponde',
						number_legal_rep:(dt.number_legal_rep!=null)?dt.number_legal_rep:'No Corresponde',
						website:dt.website,
						social:dt.social,
						telephone:dt.telephone,
						cellphone:dt.cellphone,
						email:dt.email,
						operation_department_id:dt.operation_department.data,
						type_society:dt.type_society
					};
					this.pj = this.legalData.type_society;
					this.legal = this.fb.group(
						{
							type:[this.type.toUpperCase()],
							user_id:[this.id],
							website:[(this.legalData.website!=null)?this.legalData.website:'', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
							social:[(this.legalData.social!=null)?this.legalData.social:'', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
							telephone:[(this.legalData.telephone!=null)?this.legalData.telephone:'', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
							cellphone:[(this.legalData.cellphone!=null)?this.legalData.cellphone:'', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
							email:[this.legalData.email, Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
						},{
							validator:[this.phone_cell],
						}
					);
					this.isReady.emit('ready');
					this.aux.next('getLegal');
				},
	      		error=>{
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * formulario de asociacion/colectivo
	 *
	 * @param      {string}  status  estado crear/editar
	 * @param      {string}  idP     id de perfil
	 */
	formPersonColective(status, idP=''){
		if(status=='0'){
			this.colective = this.fb.group(
				{
					type:[this.type.toUpperCase()],
					user_id:[this.id],
					rep_first_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					rep_last_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					rep_document_type:['', Validators.required],
					rep_document:['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(12)])],
					rep_exp:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					rep_nationality:['', Validators.compose([Validators.required, Validators.maxLength(20)])],
					rep_telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					rep_cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					rep_email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
					rep_document_designation:['', Validators.compose([Validators.required])],
					collective_name:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
					website:['', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
					social:['', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
					telephone:['', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
					cellphone:['', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
					email:['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
					operation_department_id:['', Validators.required],
				},{
					validator:[this.phone_cell, this.rep_phone_cell],
				}
			);
		}
		else{
			this.idP = idP;
			let urlApi = environment.apiUrl+'/persons/'+idP;
			const httpOptions = {
			  	headers: new HttpHeaders({
			    	'Accept':'application/json',
			    	
			  	}),
			  	observe: 'response' as 'body'
			};
			let resp:Observable<any> = this.http.get(urlApi,httpOptions);
			resp.subscribe(
				response=>{
					let dt = response.body.data.perfil_data.data;
					this.colectiveData = {
						rep_first_name:dt.rep_first_name,
						rep_last_name:dt.rep_last_name,
						rep_document_type:dt.rep_document_type,
						rep_document:dt.rep_document,
						rep_exp:dt.rep_exp,
						rep_nationality:dt.rep_nationality,
						rep_telephone:(dt.rep_telephone!=null)?dt.rep_telephone:'-',
						rep_cellphone:(dt.rep_cellphone!=null)?dt.rep_cellphone:'-',
						rep_email:dt.rep_email,
						rep_document_designation:dt.rep_document_designation.data,
						collective_name:dt.collective_name,
						website:dt.website,
						social:dt.social,
						telephone:dt.telephone,
						cellphone:dt.cellphone,
						email:dt.email,
						operation_department_id:dt.operation_department.data,
					};
					this.colective = this.fb.group(
						{
							type:[this.type.toUpperCase()],
							user_id:[this.id],
							website:[(this.colectiveData.website!=null)?this.colectiveData.website:'', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
							social:[(this.colectiveData.social!=null)?this.colectiveData.social:'', Validators.pattern(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#%[\]@!\$&'\(\)\*\+,;=.]+$/)],
							telephone:[(this.colectiveData.telephone!=null)?this.colectiveData.telephone:'', Validators.compose([Validators.minLength(6), Validators.maxLength(12)])],
							cellphone:[(this.colectiveData.cellphone!=null)?this.colectiveData.cellphone:'', Validators.compose([Validators.minLength(8), Validators.maxLength(12)])],
							email:[this.colectiveData.email, Validators.compose([Validators.required, Validators.email, Validators.maxLength(180)])],
							rep_document_designation:[this.colectiveData.rep_document_designation.unique_code, Validators.compose([Validators.required])],
						},{
							validator:[this.phone_cell],
						}
					);
					this.isReady.emit('ready');
					this.aux.next('getColective');
				},
	      		error=>{
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * marca errores en un formulario
	 *
	 * @param      {string}   controlName     nombre de formulario
	 * @param      {string}   validationType  tipo de validacion a verificar
	 * @return     {boolean}  valor de error
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.natural.controls[controlName];
		if (!control || !this.submitted) {
			return false;
		}
		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	/**
	 * comprobar que phone cell tenga algun valor
	 *
	 * @param      {FormGroup}  form    formulario
	 */
	phone_cell(form:FormGroup){
		const condition = form.get('telephone').value == '' && form.get('cellphone').value == '';
		return (condition)?{phone_cell:true}:null;
	}
	/**
	 * comprobar que rep_phone rep_cell tenga algun valor
	 *
	 * @param      {FormGroup}  form    formulario
	 */
	rep_phone_cell(form:FormGroup){
		const condition = form.get('rep_telephone').value == '' && form.get('rep_cellphone').value == '';
		return (condition)?{rep_phone_cell:true}:null;
	}
	/**
	 * envia datos de perfil
	 * persona natural
	 * para crear o editar el perfil
	 */
	enviarN(){
		this.submitted = true;
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/x-www-form-urlencoded',		    	
		  	}),
		};		
		const controls = this.natural.controls;
		if (this.natural.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			this.toastr.error('Complete los Campos del Formulario antes de continuar.','¡Advertencia!');
			setTimeout(()=>{this.submitted = false;this.aux.next('errorValid');}, 1000);
			return;	
		}
		else{
			let apiUrl = environment.apiUrl
			if(this.status=='0'){
				apiUrl += '/persons';
				if(typeof this.natural.value.birthday == 'object'){
					this.natural.value.birthday = this.natural.value.birthday.format('DD/MM/YYYY');
				}
			}
			else{
				apiUrl += '/persons/'+this.idP;
			}
			let iForm:any = <person_natural>JSON.parse(JSON.stringify(this.natural.value));
			const params = new HttpParams({
	          fromObject: iForm
	        });
	        let resp:Observable<any>;
	        if(this.status=='0')
				resp= this.http.post(apiUrl, params , httpOptions);
			else
				resp= this.http.put(apiUrl, params , httpOptions);
			resp.subscribe(
				data=>{
					if(this.status=='0'){
						this.toastr.success('Perfil Creado', '¡Éxito!');
					}
					else{
						this.toastr.success('Perfil Actualizado', '¡Éxito!');
					}
					setTimeout(()=>{
						if(this.returnUrl){
							let dataP = {
								id: data.data.id,
								perfil: 'pn',
								name: `${this.natural.value.first_name} ${this.natural.value.first_name}`,
								email: this.natural.value.email,
								juridica: ''
							}
							const navigationExtras: NavigationExtras = {state: {perfil_data: dataP}};
							this.router.navigate([this.returnUrl], navigationExtras);
						}
						else{
							this.router.navigate(['/perfil/seleccionar']);	
						}
					}, 1000);
					
				},
	      		error=>{
	      			setTimeout(()=>{this.submitted = false;this.aux.next('error');}, 1000);
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * envia datos de perfil
	 * persona juridica
	 * para crear o editar el perfil
	 */
	enviarL(){
		this.submitted = true;
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/x-www-form-urlencoded',		    	
		  	})
		};
		const controls = this.legal.controls;
		if (this.legal.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			this.toastr.error('Complete los Campos del Formulario antes de continuar.','¡Advertencia!');
			setTimeout(()=>{this.submitted = false;this.aux.next('errorValid');}, 1000);
			return;
		}
		else{
			let apiUrl = environment.apiUrl
			if(this.status=='0'){
				apiUrl += '/persons';
			}
			else{
				apiUrl += '/persons/'+this.idP;
			}
			let iForm:any = <person_legal>JSON.parse(JSON.stringify(this.legal.value));
			const params = new HttpParams({
	          fromObject: iForm
	        });
	        let resp:Observable<any>;
	        if(this.status=='0')
				resp= this.http.post(apiUrl, params , httpOptions);
			else
				resp= this.http.put(apiUrl, params , httpOptions);
			resp.subscribe(
				data=>{
					if(this.status=='0'){
						this.toastr.success('Perfil Creado', '¡Éxito!');
					}
					else{
						this.toastr.success('Perfil Actualizado', '¡Éxito!');
					}
					setTimeout(()=>{
						if(this.returnUrl){
							let dataP = {
								id: data.data.id,
								perfil: 'pj',
								name: this.legal.value.business_name,
								email: this.legal.value.email,
								juridica: this.legal.value.type_society
							}
							const navigationExtras: NavigationExtras = {state: {perfil_data: dataP}};
							this.router.navigate([this.returnUrl], navigationExtras);
						}
						else{
							this.router.navigate(['/perfil/seleccionar']);	
						}
					}, 1000);
				},
	      		error=>{
	      			setTimeout(()=>{this.submitted = false;this.aux.next('error');}, 1000);
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * envia datos de perfil
	 * asociacion/colectivo
	 * para crear o editar el perfil
	 */
	enviarC(){
		this.submitted = true;
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Content-Type':  'application/x-www-form-urlencoded',		    	
		  	})
		};		
		const controls = this.colective.controls;
		if (this.colective.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			this.toastr.error('Complete los Campos del Formulario antes de continuar.','¡Advertencia!');
			setTimeout(()=>{this.submitted = false;this.aux.next('errorValid');}, 1000);
			return;
		}
		else{
			let apiUrl = environment.apiUrl
			if(this.status=='0'){
				apiUrl += '/persons';
			}
			else{
				apiUrl += '/persons/'+this.idP;
			}
			let iForm:any = <person_legal>JSON.parse(JSON.stringify(this.colective.value));
			const params = new HttpParams({
	          fromObject: iForm
	        });
	        let resp:Observable<any>;
	        if(this.status=='0')
				resp= this.http.post(apiUrl, params , httpOptions);
			else
				resp= this.http.put(apiUrl, params , httpOptions);
			resp.subscribe(
				data=>{
					if(this.status=='0'){
						this.toastr.success('Perfil Creado', '¡Éxito!');
					}
					else{
						this.toastr.success('Perfil Actualizado', '¡Éxito!');
					}
					setTimeout(()=>{
						if(this.returnUrl){
							let dataP = {
								id: data.data.id,
								perfil: 'ac',
								name: this.colective.value.collective_name,
								email: this.colective.value.email,
								juridica: ''
							}
							const navigationExtras: NavigationExtras = {state: {perfil_data: dataP}};
							this.router.navigate([this.returnUrl], navigationExtras);
						}
						else{
							this.router.navigate(['/perfil/seleccionar']);	
						}
					}, 1000);
				},
	      		error=>{
	      			setTimeout(()=>{this.submitted = false;this.aux.next('error');}, 1000);
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * obtine los datos de departamentos de Bolivia
	 */
	getDepto(){
		let urlApi = environment.apiUrl+'/countries/code/BO';
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Accept':'application/json',
		    	
		  	}),
		  	observe: 'response' as 'body'
		};
		let resp:Observable<any> = this.http.get(urlApi,httpOptions);
		resp.subscribe(
			response=>{
				for(let d of  response.body.data.states.data){
					this.depto.push(d);	
				}
				if(this.title!='Persona Juridica'){
					this.isReady.emit('ready');
				}
				this.aux.next('getDep');
			},
      		error=>{
      			this.catchError(error);
      		},
		);
	}
	/**
	 * obtiene los tipos de sociedad
	 */
	getTSO(){
		let urlApi = environment.apiUrl+'/a/catalog_items/code/TSO?include=catalog_items';
		const httpOptions = {
		  	headers: new HttpHeaders({
		    	'Accept':'application/json',
		    	
		  	}),
		  	observe: 'response' as 'body'
		};
		let resp:Observable<any> = this.http.get(urlApi,httpOptions);
		resp.subscribe(
			response=>{
				for(let t of response.body.data.catalog_items.data){
					this.tso.push(t);	
				}
				this.isReady.emit('ready');
				this.aux.next('getTSO');
			},
      		error=>{
      			this.catchError(error);
      		},
		);
	}
	/**
	 * manejo de cambios de validacion en tipo de documento
	 *
	 * @param      {FormGroup}  form   formulario
	 * @param      {string}     value   valor
	 * @param      {string}     field   elemento
	 */
	changeDocument(form: FormGroup, value: string, field: string=''){
		if(value=='CI'){
			form.get(field+'exp').setValidators([Validators.required, Validators.maxLength(20)]);
			form.get(field+'nationality').setValidators([Validators.maxLength(20)]);
		}
		else{
			form.get(field+'exp').setValidators([Validators.maxLength(20)]);
			form.get(field+'nationality').setValidators([Validators.required, Validators.maxLength(20)]);
		}
		if(field==''){
			form.patchValue({exp:'',nationality:''});	
		}
		else{
			form.patchValue({rep_exp:'',rep_nationality:''});	
		}
		form.get(field+'exp').updateValueAndValidity();
		form.get(field+'nationality').updateValueAndValidity();
	}
	/**
	 * manejo de cambios para persona juridica
	 *
	 * @param      {<any>}  event   valores
	 */
	handleSelection(event) {
	    if (event.option.selected) {
	      	event.source.deselectAll();
	      	event.option._setSelected(true);
	      	this.pj = event.option.value;
	      	switch (this.pj) {
		      	case "Sociedad Comercial":
		      		this.legal.get('code_fundempresa').setValidators([Validators.required, Validators.maxLength(100)]);
		      		this.legal.get('type_business_id').setValidators([Validators.required]);
					this.legal.get('number_testimony').setValidators([]);
					this.legal.get('number_resolution').setValidators([]);
					this.legal.get('nit').setValidators([Validators.required, Validators.maxLength(20)]);
		      		break;
		      	case "Sociedad Civil":
		      		this.legal.get('code_fundempresa').setValidators([]);
		      		this.legal.get('type_business_id').setValidators([]);
					this.legal.get('number_testimony').setValidators([Validators.required, Validators.maxLength(100), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')]);
					this.legal.get('number_resolution').setValidators([]);
					this.legal.get('nit').setValidators([]);
		      		break;
		      	case "Fundación":
		      	case "Asociación Sin Fines De Lucro":
		      	case "Asociación / Organización Social":
		      		this.legal.get('code_fundempresa').setValidators([]);
		      		this.legal.get('type_business_id').setValidators([]);
					this.legal.get('number_testimony').setValidators([]);
					this.legal.get('number_resolution').setValidators([Validators.required, Validators.maxLength(100), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')]);
					this.legal.get('nit').setValidators([]);
		      		break;
		      	default:
		      		this.legal.get('code_fundempresa').setValidators([Validators.required, Validators.maxLength(100)]);
		      		this.legal.get('type_business_id').setValidators([Validators.required]);
					this.legal.get('number_testimony').setValidators([]);
					this.legal.get('number_resolution').setValidators([]);
					this.legal.get('nit').setValidators([Validators.required, Validators.maxLength(20)]);
		      		break;
	      	}
	      	this.legal.get('code_fundempresa').updateValueAndValidity();
	      	this.legal.get('type_business_id').updateValueAndValidity();
			this.legal.get('number_testimony').updateValueAndValidity();
			this.legal.get('number_resolution').updateValueAndValidity();
			this.legal.get('nit').updateValueAndValidity();
			this.legal.get('number_legal_rep').setValidators([Validators.required, Validators.maxLength(20), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')]);
			this.legal.get('number_legal_rep').updateValueAndValidity();
			this.legal.patchValue({
				type:this.type.toUpperCase(),
				user_id:this.id,
				rep_first_name:'',
				rep_last_name:'',
				rep_document_type:'',
				rep_document:'',
				rep_exp:'',
				rep_nationality:'',
				rep_telephone:'',
				rep_cellphone:'',
				rep_email:'',
				business_name:'',
				code_fundempresa:'',
				number_testimony:'',
				number_resolution:'',
				type_business_id:'',
				nit:'',
				number_legal_rep:'',
				website:'',
				social:'',
				telephone:'',
				cellphone:'',
				email:'',
				operation_department_id:'',
				type_society:this.pj,
			});
	    }
	    else
	    	this.pj = '';	
	}
	/**
	 * cambio en tipo de sociedad 
	 * persona juridica
	 *
	 * @param      {string}  type  tipo de sociedad
	 */
	changeTSO(type){
		if(type=='qnwmkv5704blag6r'){
			this.legal.get('number_legal_rep').setValidators([Validators.maxLength(20), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')]);
		}
		else{
			this.legal.get('number_legal_rep').setValidators([Validators.required, Validators.maxLength(20), Validators.pattern('^([A-Z\-0-9])+\/([0-9]){4}$')]);
		}
		this.legal.get('number_legal_rep').updateValueAndValidity();
	}
	/**
	 * precarga de archivos 
	 * asociacion/colectivo
	 */
	changeTab(){
		if(this.status=='1' && this.componentRef.directiveRef.dropzone().files.length==0){
			this.preload();
		}
	}
	/**
	 * precarga de archivo
	 */
	preload(){
		if(this.colectiveData.rep_document_designation.unique_code && this.colectiveData.rep_document_designation.unique_code!=''){
			let mockFile = {
                name: this.colectiveData.rep_document_designation.old_name,
                type: this.colectiveData.rep_document_designation.mime_type,
                size: this.colectiveData.rep_document_designation.size,
                accepted: true,
                status: 'success',
                upload:{}
            };
            this.componentRef.directiveRef.dropzone().emit('addedfile',mockFile);
            this.componentRef.directiveRef.dropzone().emit('complete',mockFile);
            this.componentRef.directiveRef.dropzone().files.push(mockFile);
		}
	}
	/**
	 * agregar el valor del archivo en el formulario
	 *
	 * @param      {response}  event   respuesta de carga de archivo
	 */
	addFile(event){
		let response = event[1];
		this.colective.patchValue({rep_document_designation:response.data.unique_code});
	}
	/**
	 * eliminar archivo
	 *
	 * @param      {archivo}  event datos de archivo
	 */
	rmFile(event){
		let response = event[1];
		this.colective.get('rep_document_designation').reset();
	}
	/**
	 * agrega datos al envio de archivos al servidor
	 *
	 * @param      {archivo}  event  datos a ser enviados
	 */
	addHash(event){
		event[2].append('type', 'PerfilPerson');
	}
	/**
	 * manejo de errores en archivo
	 *
	 * @param      {error}  e       detalle del error producido
	 * @param      {archivo}  file  datos del archivo
	 */
	manageError(e, file){
		if(typeof e == 'object'){
			this.toastr.error(e.message);	
		}
		else{			
			this.toastr.error(e.toString());
		}
		this.componentRef.directiveRef.dropzone().removeFile(file);
	}
	/**
	 * manejo de errores
	 *
	 * @param      {error}  e  error producido por peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * regresar a la pagina incial de perfil
	 */
	close(){
		if(!this.submitted){
			if(this.returnUrl){
				this.router.navigate([this.returnUrl]);
			}
			else{
				this.cancel.emit();	
			}			
		}
	}
}
/**
 * interface de formulario 
 * persona natural
 */
export interface person_natural {
  	type?:string;
	user_id?:string;
	person_id?:string;
	first_name?:string;
	last_name?:string;
	birthday?:string;
	document_type?:string;
	document?:string;
	exp?:string;
	nationality?:string;
	telephone?:string;
	cellphone?:string;
	email?:string;
	department_id?:any;
}
/**
 * interface de formulario 
 * persona juridica
 */
export interface person_legal {
  	type?:string;
	user_id?:string;
	rep_first_name?:string;
	rep_last_name?:string;
	rep_document_type?:string;
	rep_document?:string;
	rep_exp?:string;
	rep_nationality?:string;
	rep_telephone?:string;
	rep_cellphone?:string;
	rep_email?:string;
	business_name?:string;
	code_fundempresa?:string;
	number_testimony?:string;
	number_resolution?:string;
	type_business_id?:any;
	nit?:string;
	number_legal_rep?:string;
	website?:string;
	social?:string;
	telephone?:string;
	cellphone?:string;
	email?:string;
	operation_department_id?:any;
	type_society?:string;
}
/**
 * interface de formulario 
 * asociacion/colectivo
 */
export interface person_colective {
  	type?:string;
	user_id?:string;
	rep_first_name?:string;
	rep_last_name?:string;
	rep_document_type?:string;
	rep_document?:string;
	rep_exp?:string;
	rep_nationality?:string;
	rep_telephone?:string;
	rep_cellphone?:string;
	rep_email?:string;
	rep_document_designation?:any;
	collective_name?:string;
	website?:string;
	social?:string;
	telephone?:string;
	cellphone?:string;
	email?:string;
	operation_department_id?:any;
}