import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MatTableDataSource } from '@angular/material';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, from  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';

@Component({
	selector: 'kt-table-budget',
	templateUrl: './table-budget.component.html',
	styleUrls: ['./table-budget.component.scss']
})

export class TableBudgetComponent implements OnInit {
	@Input() field: string;
	@Input() group: FormGroup;
	@Input() limit: number;
	@Input() code: string;
	@Input() status: string = '';
	review: boolean;
	preview: boolean = false;
	/*---variables budget---*/
	formBudget: FormGroup;
	displayCols: string [] = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total', 'options'];
	data: any[]=[];
	item: any[] = [];
	options: options[] = [];
	buttonModal:string = 'Agregar';
	modal: NgbModalRef;
	indexEdit:number;
	isProccessing: boolean = false;
	totalOTF: number = 0;
	totalPIU: number = 0;
	total: number = 0;
	aux = new BehaviorSubject<any>(null);
	maxRows: number;
	ready: boolean = false;

	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder, 
		private http: HttpClient, 
		private toastr: ToastrService
	){}
	/**
	 * inicializacion de pagina
	 */
	ngOnInit() {
		this.initColumns();
		this.maxRows = this.getMax();
		this.initForm();
	}
	/**
	 * inicializar formulario
	 */
	initForm(){
		if(this.group.get(this.field).value){
			this.item = this.group.get(this.field).value;
			this.item.sort((a,b)=>{return b.financiamiento.localeCompare(a.financiamiento);});
			this.data = [...this.item];
			this.upTotal();
		}
		if(!this.review){
			this.initOptions(this.code);
			this.formBudget = this.fb.group(
				{
					financiamiento:['', Validators.required],
					itemOTF:['', Validators.compose([Validators.required, Validators.maxLength(100)])],
					itemPIU:['', Validators.required],
					detalle:['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
					cantidad:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					costo_unitario:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					costo_total:['', Validators.required]
				}
			);
			this.changeItem();
		}
	}
	/**
	 * abrir modal para agregar 
	 * datos a la tabla
	 *
	 * @param      {<modal>}  content  modal para agregar datos
	 */
	openLarge(content) {
		this.buttonModal = 'Agregar';
		if(this.maxRows == 0 || (this.maxRows != 0 && this.item.length < this.maxRows)){
			this.formBudget.reset({
				financiamiento:'',
				itemOTF:'',
				itemPIU:'',
				detalle:'',
				cantidad:'',
				costo_unitario:'',
				costo_total:''
			});
	        this.modal = this.modalService.open(content, {
	            size: 'lg',
	            backdrop : 'static',
	      		keyboard : false
	        });
	    }
		this.isProccessing = false;
    }
    /**
     * agregar o editar datos 
     * en la tabla
     *
     * @param      {<any>}  event   boton agregar
     */
    aeI(event){
    	event.preventDefault();
		event.stopPropagation();
		if(!this.preview){
			if(this.formBudget.valid){
				this.isProccessing = true;
				if(this.buttonModal=='Agregar'){
					this.item.push(this.prepare());
				}else{
					this.item[this.indexEdit] = this.formBudget.value;
				}
				this.item.sort((a,b)=>{return b.financiamiento.localeCompare(a.financiamiento);});
				this.data = [...this.item];
				this.group.get(this.field).setValue(this.item);
				this.upTotal();
				this.modal.close();
				this.isProccessing = false;
			}else{
				this.validateAllFormFields(this.formBudget);
			}
		}
    }
    /**
     * activa la edicion de datos de los 
     * elementos de una tabla
     *
     * @param      {number}  index    index del elemento
     * @param      {any}  element  datos del elemento
     * @param      {modal}  mod      modal para agregar datos
     */
    edit(index, element, mod){
		this.buttonModal = 'Editar';
		this.indexEdit = index;
		this.formBudget.patchValue(this.setPrepare(element));
		this.formBudget.updateValueAndValidity();
		this.changeType(element.financiamiento);
		this.modal = this.modalService.open(mod, {
            size: 'lg',
            backdrop : 'static',
      		keyboard : false
        });
        this.isProccessing = false;
	}
	/**
	 * eliminar fila de la tabla
	 *
	 * @param      {number}  index    index del elemento
	 * @param      {<any>}  element  datos del elemento
	 */
	delete(index, element){
		this.item.splice(index,1);
		if(this.item.length==0){
			this.group.get(this.field).reset();	
		}else{
			this.group.get(this.field).setValue(this.item);
		}
		this.upTotal();
		this.data = [...this.item];
	}
	/**
	 * inicializar opciones
	 *
	 * @param      {string}  type    tipo de opciones
	 */
	initOptions(type: string){
		this.getOptions(type);
	}
	/**
	 * obtener opciones 
	 * requeridas
	 *
	 * @param      {string}  type    tipo de opcion
	 */
	getOptions(type: string){
		if(this.status != 'submited'){
			let apiUrl = `${environment.apiUrl}/catalog_items/catalog/DS1/parent/${type}`;
			let resp:Observable<any> = this.http.get(apiUrl);
			resp.subscribe(
				response=>{
					let opt: any[] = response.data;
					this.options = [];
					opt.forEach(c=>{
						this.options.push({text: c.name, value: c.id});
					});
					this.ready = true;
					this.aux.next(`load options of table-budget`);
				},
	      		error=>{
	      			//this.catchError(error);
	      		},
			);
		}else{
			this.options = [];
		}		
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<error>}  e    error producido por peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * validar todos los campos del formulario
	 *
	 * @param      {FormGroup}  formGroup  formulario
	 */
    validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
	/**
	 * manejo del cambio en el formulario
	 *
	 * @param      {string}  value   valor seleccionado
	 */
    changeType(value: string):void{
    	switch (value) {
    		case "Programa de Intervenciones Urbanas(PIU)":
    			this.formBudget.get('itemOTF').setValidators([Validators.maxLength(100)]);
    			this.formBudget.get('itemPIU').setValidators([Validators.required]);
    			break;
    		case "Otras Fuentes de Financiamiento":
    			this.formBudget.get('itemOTF').setValidators([Validators.required, Validators.maxLength(100)]);
    			this.formBudget.get('itemPIU').setValidators([]);
    			break;    		
    		default:
    			break;
    	}
    	if(this.buttonModal == 'Agregar'){
    		this.formBudget.patchValue({
				itemOTF:'',
				itemPIU:'',
				detalle:'',
				cantidad:'',
				costo_unitario:'',
				costo_total:''
			});
    	}    	
    	this.formBudget.updateValueAndValidity();
    }
    /**
     * cambios de montos
     */
    changeItem(){
    	this.formBudget.get('cantidad').valueChanges.subscribe(v=>{
    		this.upTotalItem();
    	});
    	this.formBudget.get('costo_unitario').valueChanges.subscribe(v=>{
    		this.upTotalItem();
    	});
    }
    /**
     * actualizacion de totales de elemento
     */
    upTotalItem(){
    	if(this.formBudget.get('cantidad').valid && this.formBudget.get('costo_unitario').valid){
    		this.formBudget.get('costo_total').setValue(this.formBudget.controls.cantidad.value*this.formBudget.controls.costo_unitario.value);
    		this.formBudget.get('costo_total').updateValueAndValidity();
    	}
    }
    /**
     * actualizacion general de totales
     */
    upTotal(){
    	this.totalOTF = 0;
    	this.totalPIU = 0;
    	this.total = 0;
    	this.item.forEach(v=>{
    		this.total += parseInt(v.costo_total);
	    	switch (v.financiamiento) {
	    		case "Programa de Intervenciones Urbanas(PIU)":
	    			this.totalPIU += parseInt(v.costo_total);
	    			break;
	    		case "Otras Fuentes de Financiamiento":
	    			this.totalOTF += parseInt(v.costo_total);
	    			break;    		
	    		default:
	    			break;
	    	};
    	});    	
    }
    /**
     * funcion para generar formato de numero
     *
     * @param      {<string>}  value   valor
     * @return     {string} { numero en formato para visualizar }
     */			
    toFormatNum(value): string{
    	let r = Number(value).toLocaleString('en-GB');
    	return r;
    }
    /**
     * obtener limite maximo de elementos de la tabla
     */
    getMax(): number{
		return this.limit;
	}
	/**
	 * inicializar columnas de la tabla
	 */
	initColumns(){
		if(this.status!= 'submited'){
			this.displayCols = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total', 'options'];
		}else{
			this.displayCols = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total'];
		}		
	}
	/**
	 * formateo de datos 
	 * para ser procesados
	 */
	prepare():any{
		let data:any = {
			financiamiento: this.formBudget.get('financiamiento').value,
			item: this.formBudget.get('itemOTF').value + this.formBudget.get('itemPIU').value,
			detalle: this.formBudget.get('detalle').value,
			cantidad: this.formBudget.get('cantidad').value,
			costo_unitario: this.formBudget.get('costo_unitario').value,
			costo_total: this.formBudget.get('costo_total').value,
		};
		return data;
	}
	/**
	 * formateo de datos para ser editados
	 *
	 * @param      {<any>}  element  elemento de la tabla
	 */
	setPrepare(element){
		let data = {
			financiamiento: element.financiamiento,
			itemOTF: (element.financiamiento == 'Otras Fuentes de Financiamiento')?element.item:null,
			itemPIU: (element.financiamiento == 'Programa de Intervenciones Urbanas(PIU)')?element.item:'',
			detalle: element.detalle,
			cantidad: element.cantidad,
			costo_unitario: element.costo_unitario,
			costo_total: element.costo_total
		};
		return data;
	}
}
/**
 * interface de opciones en los items para agregar
 * en la tabla
 */
export interface options{
	text?: string;
	value?: string;
	selected?: boolean;
}