import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { DropzoneComponent , DropzoneDirective,  DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
/*---NGRX---*/
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { currentAuthToken } from '../../../../../../core/auth';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
	selector: 'kt-file',
	templateUrl: './file.component.html',
	styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit, AfterViewInit  {
	field: FieldConfig;
	group: FormGroup;
	preview: boolean = false;
	review: boolean;
	hash: string;
	config: any;
	header: any;
	inTable: boolean = false;
	deleteUrl: string = `${environment.apiUrl}/files/`;
	baseGoogle: string = '';
	@ViewChild(DropzoneComponent) componentRef?: DropzoneComponent;

	constructor(
		private toastr: ToastrService, 
		private store: Store<AppState>, 
		private http: HttpClient
	) { }
	/**
	 * inicializa la configuracion del componente
	 */
	ngOnInit() {
		let t: Observable<any> = this.store.pipe(select(currentAuthToken));
		t.subscribe(
			res=>{
				if(res!=null){
					this.header = {
						'Authorization': 'Bearer '+res,
						'Cache-Control': null,
			        	'X-Requested-With': null
					};
					if(this.field.config){
						this.field.config.headers = this.header;
						this.config = this.field.config;
					}
					else{
						this.config = {headers:this.header};
					}
					if(this.inTable){
						this.config.autoProcessQueue = false;
					}
				}
			}
		);
		if(!this.review){
			this.onChanges();
		}else{
			if(this.field.defaultvalue && this.field.defaultvalue !=''){
				this.baseGoogle = `https://docs.google.com/gview?url=${this.field.defaultvalue.url_file}&embedded=true`;
			}
		}
	}
	/**
	 * precargar archivo
	 */
	ngAfterViewInit(){
		if(!this.review){
			if(this.group.get(this.field.fieldname).value && typeof this.group.get(this.field.fieldname).value == 'object'){
				let mockFile = {
	                name: this.group.get(this.field.fieldname).value.old_name,
	                type: this.group.get(this.field.fieldname).value.mime_type,
	                size: this.group.get(this.field.fieldname).value.size,
	                accepted: true,
	                status: 'success',
	                upload:{}
	            };
	            this.componentRef.directiveRef.dropzone().emit('addedfile',mockFile);
	            this.componentRef.directiveRef.dropzone().emit('complete',mockFile);
	            this.componentRef.directiveRef.dropzone().files.push(mockFile);
			}
		}
	}
	/**
	 * Adds a file.
	 *
	 * @param      {<response>}  event   respuesta de archivo enviado correctamente
	 */
	addFile(event){
		let response = event[1];
		this.group.get(this.field.fieldname).setValue(response.data);
	}
	/**
	 * Removes a file.
	 *
	 * @param      {archivo}  event   archivo a eliminar
	 */
	rmFile(event){
		let response = event[1];
		if(this.group.get(this.field.fieldname).value && typeof this.group.get(this.field.fieldname).value == 'object' && !this.componentRef.directiveRef.dropzone().disabled){
			let apiUrl = `${this.deleteUrl}${this.group.get(this.field.fieldname).value.unique_code}`;
			if(!this.inTable){
				let resp:Observable<any> = this.http.delete(apiUrl);
				resp.subscribe(
					data=>{
						this.group.get(this.field.fieldname).reset();
					},
		      		error=>{
		      			this.catchError(error.error);
		      		},
				);		
			}else{
				this.group.get(this.field.fieldname).setValue(`delete ${apiUrl}`);
				//this.group.get(this.field.fieldname).updateValueAndValidity();
				this.group.get(this.field.fieldname).reset();
				//this.group.get(this.field.fieldname).updateValueAndValidity();
			}			
		}
	}
	/**
	 * { function_description }
	 *
	 * @param      {file}  event   agregar estado de archivo carga en tablas
	 */
	addedF(event){
		if(this.inTable){
			this.group.get(this.field.fieldname).setValue(event.status);
		}
	}
	/**
	 * agregar parametros al envio de archivo
	 *
	 * @param      {archivi}  event   archivo a enviarse
	 */
	addHash(event){
		event[2].append('type', 'Postulation');
		event[2].append('id', this.hash);
	}
	/**
	 * manejo de errores en archivos
	 *
	 * @param      {<error>}  e       error producido al cargar archivo
	 * @param      {archivo}  file    archivo en proceso
	 */
	manageError(e, file){
		if(typeof e == 'object'){
			this.toastr.error(e.message);	
		}
		else{			
			this.toastr.error(e.toString());
		}
		this.componentRef.directiveRef.dropzone().removeFile(file);
	}
	/**
	 * verifica si el archivo es requerido en el formulario
	 */
	isRequired(){
		let res = false;
		for(let v of this.field.validations){
			if(v.name=='required')
				res = true;
		}
		return res;
	}
	/**
	 * manejo de errores 
	 *
	 * @param      {<error>}  e  error producido por una peticion http
	 */
	catchError(e){
		if(!this.preview){
			if(typeof e == 'object'){
				this.toastr.error(e.error.message, '¡Advertencia!');	
			}
			else{			
				this.toastr.error(e.toString(), '¡Error!');
			}
		}
	}
	/**
	 * orden de enviar archivos cargados
	 */
	onChanges():void{
		this.group.get(this.field.fieldname).valueChanges.subscribe(val=>{
			if(this.inTable && val=='updateInTable'){
				this.componentRef.directiveRef.dropzone().processQueue();
			}
		});
	}
}
