// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User, Login } from '../../../../core/auth/';
import { Subject, BehaviorSubject, throwError } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
//ng-login
import { AuthService as sg } from '../../../../core/ng-login/auth.service';
import { FacebookLoginProvider } from '../../../../core/ng-login/providers';

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	loadingFacebook = new BehaviorSubject<boolean>(false);
	private returnUrl: any;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private authService: sg
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});
	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			name: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
			]),
			],
			emailVerify: ['', Validators.compose([
				Validators.required,
				Validators.email,
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
			])
			],
		}, {
			validator: [ConfirmPasswordValidator.MatchPassword, ConfirmPasswordValidator.MatchEmail]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;

		const _user ={
			name: controls['name'].value,
			email: controls['email'].value,
			password: controls['password'].value,
			verifyurl: `${location.origin}/verificar/correo`
		};
		this.auth.register(_user).pipe(
			tap(user => {
				if (user) {
					this.authNoticeService.setNotice(this.translate.instant(`Bienvenido ${user.username}`), 'success');
					this.store.dispatch(new Login({authToken: user.accessToken, refreshToken: user.refreshToken, expire:user.expire, confirmed: user.confirmed}));
					this.router.navigateByUrl(this.returnUrl); // Main page
				} else {
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				}
			}),
			catchError((err) => {
		        this.catchError(err);
		        return throwError(err);
		    }),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
	/*---login facebook---*/
	loginFacebook() {
	    this.loadingFacebook.next(true);
	    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
	    	this.auth
			.loginFacebook(res.authToken, `${location.origin}/verificar/correo`)
			.pipe(
				tap(user => {
					if (user) {
						this.store.dispatch(new Login({authToken: user.accessToken, refreshToken: user.refreshToken, expire:user.expire, confirmed: user.confirmed}));
						this.router.navigateByUrl(this.returnUrl); // Main page
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}
				}),
				catchError((err) => {
			        this.catchError(err);
			        return throwError(err);
			    }),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe();
	    }).catch(err => {this.loadingFacebook.next(false);});
	}
	catchError(e){
		if(typeof e == 'object'){
			this.authNoticeService.setNotice(e.error.message, 'danger');
		}
		else{			
			this.authNoticeService.setNotice(e.toString(), 'danger');
		}
	}
}
