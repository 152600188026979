import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer:DomSanitizer){}
	/**
	 * pipe Html
	 * habilita el render de cadenas a html
	 *
	 * @param      {<any>}  html    cadena con texto html
	 * @return {<html>} cadena lista para render en los blades
	 */
	transform(html){
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}
