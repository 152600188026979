import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject  } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
/*---NGRX---*/
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { currentUser, User, UserLoaded } from '../../../../../core/auth';

@Component({
  selector: 'kt-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})

export class FormUserComponent implements OnInit {
	aux = new BehaviorSubject<any>(null);
	id: string;
	user: User;
	perfil: FormGroup;
	seguridad: FormGroup;
	submitted: boolean = false;
	@Output() isReady: EventEmitter<any> = new EventEmitter();

	constructor(
		private store: Store<AppState>, 
		private fb: FormBuilder, 
		private http: HttpClient, 
		private toastr: ToastrService
	) { }
	/**
	 * obtiene el id de usuario del store
	 */
	ngOnInit() {
		let t: Observable<any> = this.store.pipe(select(currentUser));
		t.subscribe(
			res=>{
				if(res!=null && res!=this.id){
					this.user = res;
					this.id = this.user.id;
					this.inipage(this.user.username, this.user.email);
				}
			}
		);		
	}
	/**
	 * inicia la pagina
	 *
	 * @param      {string}  name    nombre de usuario
	 * @param      {string}  email   email de usuario
	 */
	inipage(name:string, email:string){
		this.perfil = this.fb.group(
			{
				user_id:[this.id],
				first_name:[name, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
			}
		);
		this.seguridad = this.fb.group(
			{
				user_id:[this.id],
				oldpassword:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
				password:['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
				confirmpassword:['', Validators.compose([Validators.required])],
			},{
				validator:this.confirm_pass
			}
		);
		this.isReady.emit(name);
		this.aux.next('create forms');
	}
	/**
	 * enviar datos del perfil
	 */
	enviarP(){
		this.submitted = true;
		const controls = this.perfil.controls;
		if (this.perfil.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			this.toastr.error('Complete los Campos del Formulario antes de continuar.','¡Advertencia!');
			setTimeout(()=>{this.submitted = false;this.aux.next('errorValid');}, 1000);
			return;	
		}
		else{
			let apiUrl = `${environment.apiUrl}/users/account/data/${this.id}`;
			let iForm:any = {name: this.perfil.value.first_name};
			let userUp= {
                id: this.user.id,
                username: this.perfil.value.first_name,
                email: this.user.email,
                confirmed: this.user.confirmed,
            };
			const params = new HttpParams({
	        	fromObject: iForm
	        });
	        const httpOptions = {
			  	headers: new HttpHeaders({
			  		'Accept':'application/json',
			    	'Content-Type':  'application/x-www-form-urlencoded'
			  	}),
			  	observe: 'response' as 'body'
			};	
			let resp:Observable<any>;
			resp = this.http.put(apiUrl, params , httpOptions);
			resp.subscribe(
				data=>{
					this.store.dispatch(new UserLoaded({ user: <User>userUp }));
					this.toastr.success(data.body.message, '¡Éxito!');
					setTimeout(()=>{this.submitted = false;this.aux.next('exito_perfil');}, 1000);
				},
	      		error=>{
	      			setTimeout(()=>{this.submitted = false;this.aux.next('error_perfil');}, 1000);
	      			this.catchError(error);
	      		},
			);
		}
	}
	/**
	 * enviar datos de seguridad
	 */
	enviarS(){
		this.submitted = true;
		const controls = this.seguridad.controls;
		if (this.seguridad.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			window.scroll({ top: 0, left: 0, behavior: 'smooth' });
			this.toastr.error('Complete los Campos del Formulario antes de continuar.','¡Advertencia!');
			setTimeout(()=>{this.submitted = false;this.aux.next('errorValid');}, 1000);
			return;	
		}
		else{
			let apiUrl = `${environment.apiUrl}/users/account/password/${this.id}`;
			let iForm:any = {
				old_password: this.seguridad.value.oldpassword,
				new_password: this.seguridad.value.password,
			};
			const params = new HttpParams({
	        	fromObject: iForm
	        });
	        const httpOptions = {
			  	headers: new HttpHeaders({
			  		'Accept':'application/json',
			    	'Content-Type':  'application/x-www-form-urlencoded'
			  	}),
			  	observe: 'response' as 'body'
			};	
			let resp:Observable<any>;
			resp= this.http.put(apiUrl, params , httpOptions);
			resp.subscribe(
				data=>{
					this.toastr.success(data.body.message, '¡Éxito!');
					setTimeout(()=>{this.submitted = false;this.aux.next('exito_seguridad');}, 1000);
				},
	      		error=>{
	      			setTimeout(()=>{this.submitted = false;this.aux.next('error_seguridad');}, 1000);
	      			this.catchError(error);
	      		},
			);
		}
	}	
	/**
	 * funcion que verifica
	 * que las contraseñas sean iguales
	 *
	 * @param      {FormGroup}  form    formulario
	 */
	confirm_pass(form:FormGroup){
		const condition = form.get('password').value != form.get('confirmpassword').value;
		return (condition)?{confirm_pass:true}:null;
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<any>}  e    error producido por una peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
}
