import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../field.interface";

@Component({
	selector: 'kt-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
	field: FieldConfig;
	group: FormGroup;
	review: boolean;
	constructor() { }

	ngOnInit() {
	}
}
