import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { FieldConfig } from "../../field.interface";
import { MatTableDataSource } from '@angular/material';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, from  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'kt-table-job',
  templateUrl: './table-job.component.html',
  styleUrls: ['./table-job.component.scss']
})

export class TableJobComponent implements OnInit {

	@Input() field: string;
	@Input() group: FormGroup;
	@Input() limit: number;
	@Input() code: string;
	@Input() status: string = '';
	review: boolean;
	preview: boolean = false;
	/*---variables budget---*/
	formBudget: FormGroup;
	displayCols: string [] = ['num', 'type', 'item', 'detail', 'quantity', 'unit', 'total', 'options'];
	data: any[]=[];
	item: any[] = [];
	options: options[] = [];
	buttonModal:string = 'Agregar';
	modal: NgbModalRef;
	indexEdit:number;
	isProccessing: boolean = false;
	totalMu: number = 0;
	totalHo: number = 0;
	total: number = 0;
	aux = new BehaviorSubject<any>(null);
	maxRows: number;
	ready: boolean = false;
	constructor(
		private modalService: NgbModal,
		private fb: FormBuilder, 
		private http: HttpClient, 
		private toastr: ToastrService
	){}
	/**
	 * inicializacion de pagina
	 */
	ngOnInit() {
		this.initColumns();
		this.maxRows = this.getMax();
		this.initForm();
	}
	/**
	 * inicializar formulario
	 */
	initForm(){
		if(this.group.get(this.field).value){
			this.item = this.group.get(this.field).value;
			this.data = [...this.item];
			this.upTotal();
		}
		if(!this.review){
			this.initOptions(this.code);
			this.formBudget = this.fb.group(
				{
					tipo:['', Validators.required],
					mujeres:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					hombres:['', Validators.compose([Validators.required, Validators.pattern(/^\d+$/), Validators.max(9999999)])],
					total:['', Validators.required]
				}
			);
			this.changeItem();
		}
	}
	/**
	 * abrir modal para agregar 
	 * datos a la tabla
	 *
	 * @param      {<modal>}  content  modal para agregar datos
	 */
	openLarge(content) {
		this.buttonModal = 'Agregar';
		if(this.maxRows == 0 || (this.maxRows != 0 && this.item.length < this.maxRows)){
			this.formBudget.reset({
				tipo:'',
				mujeres:'',
				hombres:'',
				total:'',
			});
			this.modal = this.modalService.open(content, {
				size: 'lg',
				backdrop : 'static',
				keyboard : false
			});
		}
		this.isProccessing = false;
	}
	/**
     * agregar o editar datos 
     * en la tabla
     *
     * @param      {<any>}  event   boton agregar
     */
	aeI(event){
		event.preventDefault();
		event.stopPropagation();
		if(!this.preview){
			if(this.formBudget.valid){
				this.isProccessing = true;
				if(this.buttonModal=='Agregar'){
					this.item.push(this.formBudget.value);
				}else{
					this.item[this.indexEdit] = this.formBudget.value;
				}
				this.data = [...this.item];
				this.group.get(this.field).setValue(this.item);
				this.upTotal();
				this.modal.close();
				this.isProccessing = false;
			}else{
				this.validateAllFormFields(this.formBudget);
			}
		}
	}
	/**
     * activa la edicion de datos de los 
     * elementos de una tabla
     *
     * @param      {number}  index    index del elemento
     * @param      {any}  element  datos del elemento
     * @param      {modal}  mod      modal para agregar datos
     */
	edit(index, element, mod){
		this.buttonModal = 'Editar';
		this.indexEdit = index;
		this.formBudget.patchValue(element);
		this.formBudget.updateValueAndValidity();
		this.modal = this.modalService.open(mod, {
			size: 'lg',
			backdrop : 'static',
			keyboard : false
		});
		this.isProccessing = false;
	}
	/**
	 * eliminar fila de la tabla
	 *
	 * @param      {number}  index    index del elemento
	 * @param      {<any>}  element  datos del elemento
	 */
	delete(index, element){
		this.item.splice(index,1);
		if(this.item.length==0){
			this.group.get(this.field).reset();	
		}else{
			this.group.get(this.field).setValue(this.item);
		}
		this.upTotal();
		this.data = [...this.item];
	}
	/**
	 * inicializar opciones
	 *
	 * @param      {string}  type    tipo de opciones
	 */
	initOptions(type: string){
		this.getOptions(type);
	}
	/**
	 * obtener opciones
	 * requeridas
	 *
	 * @param      {string}  type    tipo de opcion
	 */
	getOptions(type: string){
		if(this.status != 'submited'){
			let apiUrl = `${environment.apiUrl}/catalog_items/catalog/DS5/parent/${type}`;
			let resp:Observable<any> = this.http.get(apiUrl);
			resp.subscribe(
				response=>{
					let opt: any[] = response.data;
					this.options = [];
					opt.forEach(c=>{
						this.options.push({text: c.name, value: c.id});
					});
					this.ready = true;
					this.aux.next(`load options of table-job`);
				},
	      		error=>{
	      			//this.catchError(error);
	      		},
			);
		}else{
			this.options = [];
		}
	}
	/**
	 * manejo de errores
	 *
	 * @param      {<error>}  e    error producido por peticion http
	 */
	catchError(e){
		if(typeof e == 'object'){
			this.toastr.error(e.error.message, '¡Advertencia!');	
		}
		else{			
			this.toastr.error(e.toString(), '¡Error!');
		}
	}
	/**
	 * validar todos los campos del formulario
	 *
	 * @param      {FormGroup}  formGroup  formulario
	 */
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
	/**
     * cambios de montos
     */
	changeItem(){
		this.formBudget.get('mujeres').valueChanges.subscribe(v=>{
			this.upTotalItem();
		});
		this.formBudget.get('hombres').valueChanges.subscribe(v=>{
			this.upTotalItem();
		});
	}
	/**
     * actualizacion de totales de elemento
     */
	upTotalItem(){
		if(this.formBudget.get('mujeres').valid && this.formBudget.get('hombres').valid){
			this.formBudget.get('total').setValue(this.formBudget.controls.mujeres.value + this.formBudget.controls.hombres.value);
			this.formBudget.get('total').updateValueAndValidity();
		}
	}
	/**
     * actualizacion general de totales
     */
	upTotal(){
		this.totalMu = 0;
		this.totalHo = 0;
		this.total = 0;
		this.item.forEach(v=>{
			this.total += parseInt(v.total);
			this.totalMu += parseInt(v.mujeres);
			this.totalHo += parseInt(v.hombres);
		});    	
	}
	/**
     * funcion para generar formato de numero
     *
     * @param      {<string>}  value   valor
     * @return     {string} { numero en formato para visualizar }
     */	
	toFormatNum(value): string{
		let r = Number(value).toLocaleString('en-GB');
		return r;
	}
	/**
     * obtener limite maximo de elementos de la tabla
     */
	getMax(): number{
		return this.limit;
	}
	/**
	 * inicializar columnas de la tabla
	 */
	initColumns(){
		if(this.status != 'submited'){
			this.displayCols = ['num', 'type', 'woman', 'man', 'total', 'options'];
		}else{
			this.displayCols = ['num', 'type', 'woman', 'man', 'total'];
		}
	}

}
/**
 * interface de opciones en los items para agregar
 * en la tabla
 */
export interface options{
	text?: string;
	value?: string;
	selected?: boolean;
}